import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";

// ExamStatus component to show the status of the exam (e.g., active or inactive)
function ExamStatus({ isActive }) {
  return (
    <MDBadge
      variant="contained"
      color={isActive ? "success" : "error"}
      badgeContent={isActive ? "Active" : "Inactive"}
    />
  );
}

ExamStatus.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

function ExamSlider({ inSlider }) {
  return (
    <MDBadge
      variant="contained"
      color={inSlider ? "success" : "error"}
      badgeContent={inSlider ? "Yes" : "No"}
    />
  );
}

ExamSlider.propTypes = {
  inSlider: PropTypes.bool.isRequired,
};

function ExamsPage() {
  const navigate = useNavigate();
  const [exams, setExams] = useState([]);

  // Filter Input :
  const [filteredExams, setFilteredExams] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  // Fetching exams data from the API
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/V2/exame/all-exame`)
      .then((response) => {
        const fetchedExams = response.data.data;
        setExams(fetchedExams);
        setFilteredExams(fetchedExams);
      })
      .catch((error) => {
        console.error("There was an error fetching the exams data!", error);
      });
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query === "") {
      setFilteredExams(exams);
    } else {
      const filtered = exams.filter((exam) =>
        exam.categories.some((category) =>
          category.name.toLowerCase().includes(query.toLowerCase())
        )
      );
      setFilteredExams(filtered);
    }
  };

  const handleClick = (examId) => {
    navigate(`/dashboard/2-exams/edit-exam/${examId}`);
  };

  // Function to delete an exam from the database and the state
  const deleteOneExam = async (id) => {
    try {
      // Show SweetAlert2 confirmation dialog
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This exam will be permanently deleted!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        // Proceed with the deletion
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/V2/exame/all-exame/${id}`);

        // Update the local state to remove the deleted exam
        setExams((prev) => prev.filter((exam) => exam._id !== id));

        // Show success alert
        Swal.fire("Deleted!", "The exam has been deleted.", "success");
      }
    } catch (error) {
      console.error("There was an error deleting the exam!", error);

      // Show error alert
      Swal.fire("Error!", "Failed to delete the exam. Please try again.", "error");
    }
  };
  // --------------------------
  //   axios

  //     .delete(`${process.env.REACT_APP_API_URL}/api/V2/exame/all-exame/${id}`)
  //     .then(() => {
  //       // Remove the exam from the local state after successful deletion
  //       setExams((prev) => prev.filter((exam) => exam._id !== id));
  //     })
  //     .catch((error) => {
  //       console.error("There was an error deleting the exam!", error);
  //     });
  // };

  // Table data for displaying the exams
  const examsTableData = {
    columns: [
      { Header: "Title", accessor: "title", align: "left" },
      { Header: "Categories", accessor: "categories", align: "left" },
      { Header: "Technologies", accessor: "technologies", align: "left" },
      { Header: "Price", accessor: "price", align: "right" },
      { Header: "Duration", accessor: "duration", align: "right" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "InSlider", accessor: "inslider", align: "center" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],
    rows: filteredExams.map((exam) => ({
      title: exam.title,
      categories: exam.categories.map((cat) => cat.name).join(", "), // Display category names
      technologies: exam.technologies.map((tech) => tech.name).join(", "), // Display technology names
      price: exam.price,
      duration: exam.duration,
      status: <ExamStatus isActive={exam.isActive} />,
      inslider: <ExamSlider inSlider={exam.inSlider} />,
      actions: (
        <MDBox display="flex" justifyContent="center" gap={1}>
          <Tooltip title="Update Exam">
            <IconButton color="info" variant="contained" onClick={() => handleClick(exam._id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Exam">
            <IconButton color="error" onClick={() => deleteOneExam(exam._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          All Exams
        </MDTypography>
      </MDBox>
      <MDBox mt={2} mb={2} mx={2}>
        {" "}
        <TextField
          value={searchQuery}
          onChange={handleSearch}
          label="Search by Categories"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: "1rem" }}
        />
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={examsTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ExamsPage;

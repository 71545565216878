import { useState, useEffect } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
function TehnologiesPage() {
  const navigate = useNavigate();
  const [technologies, setTechnologies] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/V2/technology/technologies`)
      .then((response) => {
        const fetchedTechnologies = response.data.data;
        setTechnologies(fetchedTechnologies);
      })
      .catch((error) => {
        console.error("Error fetching technologies:", error);
      });
  }, []);

  const handleClick = (technologyId) => {
    navigate(`/dashboard/7-technologies/edit-technology/${technologyId}`);
  };

  const deleteTechnology = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/V2/technology/technologies/${id}`);
        setTechnologies((prev) => prev.filter((technology) => technology._id !== id));

        Swal.fire("Deleted!", "The technology has been deleted.", "success");
      }
    } catch (error) {
      console.error("Error deleting technology:", error);
      Swal.fire("Error!", "Failed to delete the technology. Please try again.", "error");
    }
  };

  const technologyTableData = {
    columns: [
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],
    rows: technologies.map((technology) => ({
      name: technology.name,
      actions: (
        <MDBox display="flex" justifyContent="center" gap={1}>
          <Tooltip title="Edit Technology">
            <IconButton
              color="info"
              variant="contained"
              onClick={() => handleClick(technology._id)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete technology">
            <IconButton color="error" onClick={() => deleteTechnology(technology._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          All Technologies
        </MDTypography>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={technologyTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TehnologiesPage;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function EditCategory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState({
    name: "",
  });

  const updateCategoryData = (field, value) => {
    setCategoryData((prev) => ({ ...prev, [field]: value }));
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v2/category/categories/${id}`
        );
        setCategoryData(response.data.data || { name: "" });
      } catch (error) {
        console.error("Error fetching category data", error);
      }
    };
    fetchData();
  }, [id]);

  const handleSubmit = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/V2/category/categories/${id}`,
        { name: categoryData.name },
        { headers: { "Content-Type": "application/json" } }
      );

      Swal.fire({
        title: "Success!",
        text: "The category has been updated successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      navigate("/dashboard/6-categories/categories-page");
      console.log("Response:", response.data);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to update the category.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error:", error.response.data);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Edit Category
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          Update the information about this Category.
        </MDTypography>
      </MDBox>
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5" fontWeight="medium">
                  Category Details
                </MDTypography>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="name"
                      value={categoryData.name}
                      onChange={(e) => updateCategoryData("name", e.target.value)}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
            <MDBox mt={3} display="flex" justifyContent="flex-end">
              <MDButton variant="contained" color="primary" onClick={handleSubmit}>
                Save Changes
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditCategory;

import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Select from "react-select";
import { useState, useEffect } from "react";
import axios from "axios";

function Tags({ blogData, updateBlogData }) {
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v2/category/categories`
        );
        setCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchTags = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v2/tags/all-tags`);
        setTags(response.data.data);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    fetchCategories();
    fetchTags();
  }, []);

  return (
    <MDBox>
      <MDTypography variant="h5">Settings</MDTypography>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDTypography component="label" variant="button" fontWeight="regular" color="text">
              Category
            </MDTypography>
            <Select
              options={categories.map((category) => ({
                value: category._id,
                label: category.name,
              }))}
              value={
                blogData.category
                  ? {
                      value: blogData.category,
                      label: categories.find((c) => c._id === blogData.category)?.name,
                    }
                  : null
              }
              onChange={(selectedOption) => updateBlogData("category", selectedOption?.value)}
              placeholder="Select Category"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDTypography component="label" variant="button" fontWeight="regular" color="text">
              Tags
            </MDTypography>
            <Select
              options={tags.map((tag) => ({
                value: tag._id,
                label: tag.name,
              }))}
              value={
                blogData.tags?.length
                  ? blogData.tags.map((tagId) => ({
                      value: tagId,
                      label: tags.find((t) => t._id === tagId)?.name,
                    }))
                  : []
              }
              onChange={(selectedOptions) => {
                const selectedValues = selectedOptions.map((option) => option.value);
                updateBlogData("tags", selectedValues);
              }}
              isMulti
              placeholder="Select Tags"
            />
          </Grid>
        </Grid>
        <MDBox mt={3}>
          <Switch
            checked={blogData.isActive}
            onChange={(e) => updateBlogData("isActive", e.target.checked)}
          />
          <MDTypography component="label" variant="button" fontWeight="regular" color="text">
            Is Active
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

Tags.propTypes = {
  blogData: PropTypes.object.isRequired,
  updateBlogData: PropTypes.func.isRequired,
};

export default Tags;

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Switch from "@mui/material/Switch";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function EditElectronicSupport() {
  const [supportData, setSupportData] = useState({
    file: null,
    fileUrl: "",
    isForCourse: false,
    isForExam: false,
    description: "",
  });

  const { id } = useParams();
  const navigate = useNavigate();

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setSupportData((prevData) => ({
        ...prevData,
        file,
      }));
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "*",
    maxFiles: 1,
  });

  useEffect(() => {
    const fetchSupportData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/V2/support/${id}`);
        const support = response.data.support;

        setSupportData({
          file: null,
          fileUrl: support.fileUrl,
          isForCourse: support.isForCourse,
          isForExam: support.isForExam,
          description: support.description,
        });
      } catch (error) {
        console.error("Error fetching electronic support data:", error.message);
      }
    };

    fetchSupportData();
  }, [id]);

  const updateSupportData = (field, value) => {
    setSupportData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();

      for (const [key, value] of Object.entries(supportData)) {
        if (key !== "file" || value) {
          formData.append(key, value);
        }
      }

      if (supportData.file) {
        formData.append("file", supportData.file);
      }

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/V2/support/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          title: "Success!",
          text: "Electronic support updated successfully!",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/dashboard/electronic-supports");
        });
      }
    } catch (error) {
      console.error("Error updating electronic support:", error.response?.data || error.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Edit Electronic Support
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          Modify the details of your electronic support below.
        </MDTypography>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <MDBox mt={4}>
          <Card>
            <MDBox p={3}>
              <MDTypography variant="h5">Edit Electronic Support</MDTypography>
              <MDBox mt={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      File
                    </MDTypography>
                    {supportData.fileUrl && !supportData.file && (
                      <a href={supportData.fileUrl} target="_blank" rel="noopener noreferrer">
                        Current File
                      </a>
                    )}
                    <MDBox
                      {...getRootProps()}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      border="2px dashed"
                      borderRadius="8px"
                      borderColor="grey.400"
                      bgcolor="grey.100"
                      py={4}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "grey.200",
                        },
                        transition: "background-color 0.2s ease-in-out",
                      }}
                    >
                      <input {...getInputProps()} />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="textSecondary"
                        mb={1}
                      >
                        Drag & drop a file here or
                      </MDTypography>
                      <MDButton variant="contained" color="primary">
                        Browse Files
                      </MDButton>
                    </MDBox>
                  </Grid>

                  <Grid item xs={12}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Description
                    </MDTypography>
                    <FormField
                      type="text"
                      label="Description"
                      value={supportData.description}
                      onChange={(e) => updateSupportData("description", e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      For Course
                    </MDTypography>
                    <Switch
                      checked={supportData.isForCourse}
                      onChange={(e) => updateSupportData("isForCourse", e.target.checked)}
                      color="primary"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      For Exam
                    </MDTypography>
                    <Switch
                      checked={supportData.isForExam}
                      onChange={(e) => updateSupportData("isForExam", e.target.checked)}
                      color="primary"
                    />
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox mt={4} display="flex" justifyContent="flex-end">
                <MDButton variant="contained" color="primary" onClick={handleSubmit}>
                  Update Support
                </MDButton>
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

EditElectronicSupport.propTypes = {
  supportData: PropTypes.shape({
    file: PropTypes.object,
    fileUrl: PropTypes.string,
    isForCourse: PropTypes.bool,
    isForExam: PropTypes.bool,
    description: PropTypes.string,
  }),
};

export default EditElectronicSupport;

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import Swal from "sweetalert2";

// Badge Component for Category
function CategoryBadge({ category }) {
  const colorMap = {
    Technology: "info",
    Sports: "success",
    Politics: "warning",
    Health: "error",
  };

  return (
    <MDBadge
      variant="contained"
      color={colorMap[category] || "secondary"}
      badgeContent={category}
    />
  );
}

function NewsPage() {
  const navigate = useNavigate();
  const [newsItems, setNewsItems] = useState([]);

  // Fetch news data from API
  useEffect(() => {
    axios
      .get("${process.env.REACT_APP_API_URL}/api/news/all")
      .then((response) => {
        setNewsItems(response.data);
      })
      .catch((error) => {
        console.error("Error fetching news data:", error);
      });
  }, []);

  // Delete News Item
  const deleteNewsItem = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This news item will be permanently deleted!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/news/${id}`);
        setNewsItems((prev) => prev.filter((news) => news._id !== id));
        Swal.fire("Deleted!", "The news item has been deleted.", "success");
      }
    } catch (error) {
      console.error("Error deleting news item:", error);
      Swal.fire("Error!", "Failed to delete the news item.", "error");
    }
  };

  // Table Data
  const newsTableData = {
    columns: [
      { Header: "Title", accessor: "title", align: "left" },
      { Header: "Author", accessor: "author", align: "left" },
      { Header: "Date", accessor: "date", align: "center" },
      { Header: "Category", accessor: "category", align: "center" },
      { Header: "Views", accessor: "views", align: "center" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],
    rows: newsItems.map((news) => ({
      title: news.title,
      author: news.author,
      date: new Date(news.date).toLocaleDateString(),
      category: <CategoryBadge category={news.category} />,
      views: news.views,
      actions: (
        <MDBox display="flex" justifyContent="center" gap={1}>
          <Tooltip title="Edit">
            <IconButton color="info" onClick={() => navigate(`/dashboard/news/edit/${news._id}`)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton color="error" onClick={() => deleteNewsItem(news._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          News Articles
        </MDTypography>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={newsTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewsPage;

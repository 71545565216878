import { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";

function TestimonialTypeBadge({ typeOf }) {
  const color = typeOf === "individual" ? "success" : "info";
  return <MDBadge variant="contained" color={color} badgeContent={typeOf} />;
}

TestimonialTypeBadge.propTypes = {
  type: PropTypes.oneOf(["individual", "group"]).isRequired,
};

function TestimonialsPage() {
  const navigate = useNavigate();
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchTestimonials = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/V2/testmon/all-testimonials`
      );
      console.log("API Response:", response.data);
      setTestimonials(response.data || []);
      setLoading(false);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Failed to fetch testimonials from the server.",
        icon: "error",
        confirmButtonText: "OK",
      });
      setLoading(false);
      console.error("Error fetching testimonials:", error);
    }
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const handleEdit = (testimonialId) => {
    navigate(`/dashboard/12-testimonials/edit-testimonial/${testimonialId}`);
  };

  const deleteTestimonial = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This testimonial will be permanently deleted!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/V2/testmon/testimonial/${id}`);
        setTestimonials((prev) => prev.filter((testimonial) => testimonial._id !== id));
        Swal.fire("Deleted!", "The testimonial has been deleted.", "success");
      }
    } catch (error) {
      console.error("There was an error deleting the testimonial!", error);
      Swal.fire("Error!", "Failed to delete the testimonial. Please try again.", "error");
    }
  };

  const testimonialsTableData = {
    columns: [
      // { Header: "Full Name", accessor: "fullName", align: "left" },
      { Header: "Type Of", accessor: "typeOf", align: "center" },
      { Header: "Occupation", accessor: "occupation", align: "left" },
      // { Header: "Description", accessor: "description", align: "left" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],
    rows: Array.isArray(testimonials)
      ? testimonials.map((testimonial) => ({
          fullName: testimonial.fullName,
          typeOf: testimonial.typeOf,
          occupation: testimonial.occupation,
          description: testimonial.description,
          actions: (
            <MDBox display="flex" justifyContent="center" gap={1}>
              <Tooltip title="Edit Testimonial">
                <IconButton color="info" onClick={() => handleEdit(testimonial._id)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Testimonial">
                <IconButton color="error" onClick={() => deleteTestimonial(testimonial._id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </MDBox>
          ),
        }))
      : [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          All Testimonials
        </MDTypography>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={testimonialsTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TestimonialsPage;

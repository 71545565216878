import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";

import Swal from "sweetalert2";
import Select from "react-select";
import { Grid, Card, Box, Typography, Button, Input } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDInput from "components/MDInput";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};
function AddNewSolution() {
  const navigate = useNavigate();
  const [solutionData, setSolutionData] = useState({
    title: "",
    description: "",
    categories: [],
    mainlmg: null,
    whyChoosUs: "",
  });
  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});

  const updateSolutionData = (field, value) => {
    setSolutionData((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v2/category/categories`
        );
        setCategories(categoryResponse.data.data || []);
      } catch (error) {
        console.error("Error fetching categories", error);
      }
    };
    fetchData();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSolutionData((prevState) => ({
        ...prevState,
        mainlmg: file,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!solutionData.title) newErrors.title = "Title is required";
    if (!solutionData.description) newErrors.description = "Description is required";
    if (solutionData.categories.length === 0)
      newErrors.categories = "At least one category is required";
    if (!solutionData.mainlmg) newErrors.mainlmg = "Image is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const formData = new FormData();
    formData.append("title", solutionData.title);
    formData.append("description", solutionData.description);
    formData.append("categories", JSON.stringify(solutionData.categories));
    formData.append("mainlmg", solutionData.mainlmg);
    formData.append("whyChoosUs", solutionData.whyChoosUs);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/V2/solution/add`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      Swal.fire({
        title: "Success!",
        text: "Solution added successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      navigate("/dashboard/9-solutions/solutions-page");
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to add solution.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Add New Solution
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          This information will describe more about the solution.
        </MDTypography>
      </MDBox>
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5" fontWeight="medium">
                  Solution Details
                </MDTypography>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <Input
                      fullWidth
                      placeholder="Title"
                      value={solutionData.title}
                      onChange={(e) => updateSolutionData("title", e.target.value)}
                    />
                    {errors.title && <MDTypography color="error">{errors.title}</MDTypography>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Input
                      fullWidth
                      placeholder="Description"
                      value={solutionData.description}
                      onChange={(e) => updateSolutionData("description", e.target.value)}
                    />
                    {errors.description && (
                      <MDTypography color="error">{errors.description}</MDTypography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label="Why Choose Us"
                      type="text"
                      placeholder="Why Choose Us"
                      value={solutionData.whyChoosUs}
                      onChange={(e) =>
                        updateSolutionData(
                          "whyChoosUs",
                          e.target.value.split(",").map((s) => s.trim())
                        )
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Why Choose Us
                    </MDTypography>
                    <Select
                      options={[
                        { label: "Quality", value: "Quality" },
                        { label: "Affordability", value: "Affordability" },
                        { label: "Expertise", value: "Expertise" },
                        { label: "Customer Support", value: "Customer Support" },
                      ]}
                      value={
                        solutionData.whyChoosUs
                          ? { label: solutionData.whyChoosUs, value: solutionData.whyChoosUs }
                          : null
                      }
                      onChange={(selectedOption) =>
                        updateSolutionData("whyChoosUs", selectedOption.value)
                      }
                      placeholder="Select whyChoosUs"
                    />
                  </Grid> */}

                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Categories
                    </MDTypography>
                    {Array.isArray(categories) && (
                      <Select
                        options={categories.map((item) => ({
                          label: item.name,
                          value: item._id,
                        }))}
                        value={
                          solutionData.categories
                            ? categories
                                .filter((cat) => solutionData.categories.includes(cat._id))
                                .map((cat) => ({ label: cat.name, value: cat._id }))
                            : []
                        }
                        onChange={(selectedOptions) =>
                          updateSolutionData(
                            "categories",
                            selectedOptions ? selectedOptions.map((opt) => opt.value) : []
                          )
                        }
                        isMulti
                        placeholder="Select Categories"
                      />
                    )}
                    {errors.categories && (
                      <MDTypography color="error">{errors.categories}</MDTypography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" fontWeight="light" color="textSecondary" mb={1}>
                      Upload Image
                    </Typography>
                    {!solutionData.mainlmg && (
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        border="2px dashed"
                        borderColor="grey.400"
                        bgcolor="grey.100"
                        borderRadius="8px"
                        py={5}
                        sx={{
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "grey.200" },
                          transition: "background-color 0.3s ease-in-out",
                        }}
                      >
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="textSecondary"
                          mb={1}
                        >
                          Drag & drop an image here, or
                        </MDTypography>
                        <MDButton
                          variant="contained"
                          color="primary"
                          size="medium"
                          onClick={() => document.getElementById("file-input").click()}
                        >
                          Browse Files
                        </MDButton>
                      </MDBox>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      id="file-input"
                    />
                    {solutionData.mainlmg && (
                      <MDBox
                        mt={3}
                        textAlign="center"
                        onClick={() => document.getElementById("file-input").click()}
                        sx={{ cursor: "pointer" }}
                      >
                        <MDTypography variant="body2" color="textSecondary">
                          Uploaded Image:
                        </MDTypography>
                        <img
                          src={URL.createObjectURL(solutionData.mainlmg)}
                          alt="Solution"
                          style={{
                            width: "100%",
                            maxWidth: "150px",
                            marginTop: "10px",
                            borderRadius: "8px",
                          }}
                        />
                        <MDTypography variant="body2" color="textSecondary" mt={2}>
                          Click to upload a new image
                        </MDTypography>
                      </MDBox>
                    )}
                    {errors.mainlmg && <MDTypography color="error">{errors.mainlmg}</MDTypography>}
                  </Grid>

                  {/* img section not working  */}
                  {/* <Grid item xs={12}>
                    <Typography variant="subtitle2" fontWeight="light" color="textSecondary" mb={1}>
                      Upload Image
                    </Typography>
                    {!solutionData.mainlmg && (
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        border="2px dashed"
                        borderColor="grey.400"
                        bgcolor="grey.100"
                        borderRadius="8px"
                        py={5}
                        sx={{
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "grey.200" },
                          transition: "background-color 0.3s ease-in-out",
                        }}
                      >
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="textSecondary"
                          mb={1}
                        >
                          Drag & drop an image here, or
                        </MDTypography>
                        <MDButton
                          variant="contained"
                          color="primary"
                          size="medium"
                          onClick={() => document.getElementById("file-input").click()}
                        >
                          Browse Files
                        </MDButton>
                      </MDBox>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      id="file-input"
                    />
                    {solutionData.mainlmg && (
                      <MDBox mt={3} textAlign="center">
                        <MDTypography variant="body2" color="textSecondary">
                          Uploaded Image:
                        </MDTypography>
                        <img
                          src={URL.createObjectURL(solutionData.mainlmg)}
                          alt="Solution"
                          style={{
                            width: "100%",
                            maxWidth: "150px",
                            marginTop: "10px",
                            borderRadius: "8px",
                          }}
                        />
                      </MDBox>
                    )}
                    {errors.mainlmg && <MDTypography color="error">{errors.mainlmg}</MDTypography>}
                  </Grid> */}
                </Grid>
              </MDBox>
            </Card>
            <MDBox mt={3} display="flex" justifyContent="flex-end">
              <MDButton variant="contained" color="primary" onClick={handleSubmit}>
                Publish
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddNewSolution;

import { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";

function SliderStatus({ isActive }) {
  return (
    <MDBadge
      variant="contained"
      color={isActive ? "success" : "error"}
      badgeContent={isActive ? "Active" : "Inactive"}
    />
  );
}

SliderStatus.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

function HomeSlidersPage() {
  const navigate = useNavigate();
  const [sliders, setSliders] = useState([]);
  const [filteredSliders, setFilteredSliders] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchSliders = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/V2/homeSlider/all-sliders`
      );
      const fetchedSliders = response.data.data || [];
      setSliders(fetchedSliders);
      setFilteredSliders(fetchedSliders);
      setLoading(false);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Failed to fetch sliders from the server.",
        icon: "error",
        confirmButtonText: "OK",
      });
      setLoading(false);
      console.error("Error fetching sliders:", error);
    }
  };

  useEffect(() => {
    fetchSliders();
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query === "") {
      setFilteredSliders(sliders);
    } else {
      const filtered = sliders.filter((slider) =>
        slider.title.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredSliders(filtered);
    }
  };

  const handleClick = (homeSliderId) => {
    navigate(`/dashboard/4-homeSlider/edit-homeSlider/${homeSliderId}`);
  };

  const deleteSlider = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This Home Slider will be permanently deleted!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/V2/homeSlider/all-sliders/${id}`);
        setSliders((prev) => prev.filter((slider) => slider._id !== id));
        setFilteredSliders((prev) => prev.filter((slider) => slider._id !== id));

        Swal.fire("Deleted!", "The Home Slider has been deleted.", "success");
      }
    } catch (error) {
      console.error("There was an error deleting the Home Slider!", error);

      Swal.fire("Error!", "Failed to delete the Home Slider. Please try again.", "error");
    }
  };

  const slidersTableData = {
    columns: [
      { Header: "Title", accessor: "title", align: "left" },
      { Header: "Description", accessor: "shortDescription", align: "left" },
      { Header: "Button Text", accessor: "buttonTxt", align: "left" },
      { Header: "Image", accessor: "image", align: "center" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],
    rows: filteredSliders.map((slider) => ({
      title: slider.title,
      shortDescription: slider.shortDescription,
      buttonTxt: slider.buttonTxt,
      image: slider.mainlmg ? (
        <img
          src={slider.mainlmg && slider.mainlmg.url ? slider.mainlmg.url : "defaultImage"}
          alt={slider.title}
          style={{
            width: "100px",
            height: "100px",
            objectFit: "cover",
            borderRadius: "8px",
          }}
        />
      ) : (
        "No Image"
      ),
      actions: (
        <MDBox display="flex" justifyContent="center" gap={1}>
          <Tooltip title="Edit Slider">
            <IconButton color="info" variant="contained" onClick={() => handleClick(slider._id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Slider">
            <IconButton color="error" onClick={() => deleteSlider(slider._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          All Home Sliders
        </MDTypography>
      </MDBox>
      <MDBox mt={2} mb={2} mx={2}>
        <TextField
          value={searchQuery}
          onChange={handleSearch}
          label="Search by Title"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: "1rem" }}
        />
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={slidersTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default HomeSlidersPage;

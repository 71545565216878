import React, { useState } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function AddCoupon() {
  const navigate = useNavigate();
  const [couponData, setCouponData] = useState({
    code: "",
    discount: "",
    expirationDate: "",
  });

  const updateCouponData = (field, value) => {
    setCouponData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v2/coupon/coupons`,
        couponData
      );

      Swal.fire({
        title: "Success!",
        text: "The coupon has been added successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      navigate("/dashboard/20-coupons/coupons-page");
      console.log("Response:", response.data);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to add the coupon.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Add New Coupon
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          This information will describe more about the coupon.
        </MDTypography>
      </MDBox>
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5" fontWeight="medium">
                  Coupon Details
                </MDTypography>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="Coupon Code"
                      value={couponData.code}
                      onChange={(e) => updateCouponData("code", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="number"
                      label="Discount (%)"
                      value={couponData.discount}
                      onChange={(e) => updateCouponData("discount", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="date"
                      label="Expiration Date"
                      value={couponData.expirationDate}
                      onChange={(e) => updateCouponData("expirationDate", e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
            <MDBox mt={3} display="flex" justifyContent="flex-end">
              <MDButton variant="contained" color="primary" onClick={handleSubmit}>
                Publish
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddCoupon;

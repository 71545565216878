import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import MDTypography from "components/MDTypography";

const Media = ({ blogData, updateBlogData }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setSelectedFile(file);
        setPreviewUrl(URL.createObjectURL(file));
        updateBlogData("mainlmg", file);
      }
    },
  });

  return (
    <MDBox>
      <MDTypography variant="h6" gutterBottom>
        Upload Main Image
      </MDTypography>

      <div
        {...getRootProps()}
        style={{
          border: "2px dashed #ccc",
          padding: "20px",
          textAlign: "center",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "150px",
        }}
      >
        <input {...getInputProps()} />
        <MDTypography variant="body2" color="textSecondary">
          Drag & drop an image here, or click to select one
        </MDTypography>
      </div>
      {previewUrl && (
        <MDBox mt={2} display="flex" justifyContent="center">
          <img
            src={previewUrl}
            alt="Preview"
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              objectFit: "cover",
              marginTop: "10px",
              display: "block",
            }}
          />
        </MDBox>
      )}
    </MDBox>
  );
};
Media.propTypes = {
  blogData: PropTypes.object.isRequired,
  updateBlogData: PropTypes.func.isRequired,
};
export default Media;

import React, { useState } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function AddNewTechnology() {
  const navigate = useNavigate();

  const [technologyData, setTechnologyData] = useState({
    name: "",
  });

  const [errors, setErrors] = useState({});

  const updateTechnologyData = (field, value) => {
    setTechnologyData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/V2/technology/technologies`,
        { name: technologyData.name },
        { headers: { "Content-Type": "application/json" } }
      );

      Swal.fire({
        title: "Success!",
        text: "The Technology has been added successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      navigate("/dashboard/7-technologies/technologies-page");
      console.log("Response:", response.data);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to add the Technology.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Add New Technology
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          This information will describe more about the Technology.
        </MDTypography>
      </MDBox>
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5" fontWeight="medium">
                  Technology Details
                </MDTypography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="Name"
                      value={technologyData.name}
                      onChange={(e) => updateTechnologyData("name", e.target.value)}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
            <MDBox mt={3} display="flex" justifyContent="flex-end">
              <MDButton variant="contained" color="primary" onClick={handleSubmit}>
                Publish
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddNewTechnology;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function EditCoupon() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [couponData, setCouponData] = useState({
    code: "",
    discount: "",
    expirationDate: "",
  });

  useEffect(() => {
    const fetchCoupon = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v2/coupon/coupons/${id}`
        );

        setCouponData({
          code: response.data.data.code,
          discount: response.data.data.discount,
          expirationDate: response.data.data.expirationDate,
        });

        console.log("Fetched Coupon Data:", response.data);
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: "Failed to fetch the coupon details.",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error("Error:", error);
      }
    };

    fetchCoupon();
  }, [id]);

  const updateCouponData = (field, value) => {
    setCouponData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v2/coupon/coupons/${id}`,
        couponData
      );

      Swal.fire({
        title: "Success!",
        text: "The coupon has been updated successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      navigate("/dashboard/20-coupons/coupons-page");
      console.log("Response:", response.data);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to update the coupon.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Edit Coupon
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          Modify the coupon details below.
        </MDTypography>
      </MDBox>
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5" fontWeight="medium">
                  Coupon Details
                </MDTypography>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="Coupon Code"
                      value={couponData.code}
                      onChange={(e) => updateCouponData("code", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="number"
                      label="Discount (%)"
                      value={couponData.discount}
                      onChange={(e) => updateCouponData("discount", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="date"
                      label="Expiration Date"
                      value={
                        couponData.expirationDate ? couponData.expirationDate.split("T")[0] : ""
                      }
                      onChange={(e) => updateCouponData("expirationDate", e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
            <MDBox mt={3} display="flex" justifyContent="flex-end">
              <MDButton variant="contained" color="primary" onClick={handleSubmit}>
                Save Changes
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditCoupon;

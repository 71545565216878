import { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";

function BlogStatus({ isActive }) {
  return (
    <MDBadge
      variant="contained"
      color={isActive ? "success" : "error"}
      badgeContent={isActive ? "Active" : "Inactive"}
    />
  );
}

BlogStatus.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

function BlogsPage() {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/V2/blog/all-blog`);
      const fetchedBlogs = response.data.data;
      setBlogs(fetchedBlogs);
      setFilteredBlogs(fetchedBlogs);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query === "") {
      setFilteredBlogs(blogs);
    } else {
      const filtered = blogs.filter((blog) =>
        blog.title.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredBlogs(filtered);
    }
  };

  const deleteOneBlog = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This blog will be permanently deleted!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/V2/blog/all-blog/${id}`);
        setBlogs((prev) => prev.filter((blog) => blog._id !== id));
        Swal.fire("Deleted!", "The blog has been deleted.", "success");
      }
    } catch (error) {
      console.error("Error deleting blog:", error);
      Swal.fire("Error!", "Failed to delete the blog. Please try again.", "error");
    }
  };

  const handleEdit = (blog) => {
    navigate(`/dashboard/blogs/edit-blog/${blog._id}`);
  };

  const blogsTableData = {
    columns: [
      { Header: "Title", accessor: "title", align: "left" },
      { Header: "Category", accessor: "category", align: "left" },
      { Header: "Tags", accessor: "tags", align: "left" },
      { Header: "Author", accessor: "author", align: "left" },
      { Header: "Image", accessor: "image", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],
    rows: filteredBlogs.map((blog) => ({
      title: blog.title,
      category: Array.isArray(blog.category)
        ? blog.category.map((cat) => cat.name).join(", ")
        : blog.category,
      tags: blog.tags.map((tag) => tag.name).join(", "),
      author: blog.author || "Unknown",
      image: blog.mainlmg ? (
        <img
          src={blog.mainlmg?.url || "defaultImage"}
          alt={blog.title}
          style={{
            width: "100px",
            height: "100px",
            objectFit: "cover",
            borderRadius: "8px",
          }}
        />
      ) : (
        "No Image"
      ),
      status: <BlogStatus isActive={blog.isActive} />,
      actions: (
        <MDBox display="flex" justifyContent="center" gap={1}>
          <Tooltip title="Edit Blog">
            <IconButton color="info" onClick={() => handleEdit(blog)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Blog">
            <IconButton color="error" onClick={() => deleteOneBlog(blog._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          All Blogs
        </MDTypography>
      </MDBox>
      <MDBox mt={2} mb={2} mx={2}>
        <TextField
          value={searchQuery}
          onChange={handleSearch}
          label="Search by Titles"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: "1rem" }}
        />
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={blogsTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BlogsPage;

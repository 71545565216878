import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Settings from "layouts/pages/account/settings";
import PrivateRoute from "../src/privateRoute";
// -------------------blogs-----------------
import EditBlog from "layouts/pages/1-blogs/edit-blog";
import BlogsPage from "layouts/pages/1-blogs/blogs-page";
import AddNewBlog from "layouts/pages/1-blogs/new-blog";

// ------------Authentification--------------
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import ResetCover from "layouts/authentication/reset-password/cover";
import NewUser from "layouts/pages/users/new-user";

import SignUpCover from "layouts/authentication/sign-up/cover";

import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";
import SchoolIcon from "@mui/icons-material/School";
import ArticleIcon from "@mui/icons-material/Article";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import WorkIcon from "@mui/icons-material/Work";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import CategoryIcon from "@mui/icons-material/Category";
import ComputerIcon from "@mui/icons-material/Computer";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import ForumIcon from "@mui/icons-material/Forum";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import HistoryIcon from "@mui/icons-material/History";
import GroupIcon from "@mui/icons-material/Group";
import PublicIcon from "@mui/icons-material/Public";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LanguageIcon from "@mui/icons-material/Language";
import CampaignIcon from "@mui/icons-material/Campaign";
import SellIcon from "@mui/icons-material/Sell";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BuildIcon from "@mui/icons-material/Build";
import IconButton from "@mui/material/IconButton";

import { navbarIconButton } from "examples/Navbars/DashboardNavbar/styles";
import { Link } from "react-router-dom";

// Images
import profilePicture from "assets/images/team-3.jpg";
//Pages
import AddNewExam from "layouts/pages/2-exams/new-exam";
import EditExam from "layouts/pages/2-exams/edit-exam";
import ExamsPage from "layouts/pages/2-exams/exams-page";
import AddNewHomeSlider from "layouts/pages/4-homeSlider/new-homeSlider";
import EditHomeSlider from "layouts/pages/4-homeSlider/edit-homeSlider";
import HomeSlidersPage from "layouts/pages/4-homeSlider/homeSliders-page";
import AddNewPortfolio from "layouts/pages/5-portfolio/new-portfolio";
import PortfolioPage from "layouts/pages/5-portfolio/portfolio-page";
import AddNewCategory from "layouts/pages/6-categories/new-category";
import AddNewCourse from "layouts/pages/3-courses/new-course";
import EditCourse from "layouts/pages/3-courses/edit-course";
import CoursesPage from "layouts/pages/3-courses/courses-page";
import AddNewService from "layouts/pages/8-services/new-service";
import EditService from "layouts/pages/8-services/edit-service";
import ServicesPage from "layouts/pages/8-services/services-page";
import EditSolution from "layouts/pages/9-soultions/edit-solution";
import AddNewSolution from "layouts/pages/9-soultions/new-solution";
import SolutionsPage from "layouts/pages/9-soultions/solutions-page";
import EditCategory from "layouts/pages/6-categories/edit-category";
import CategoriesPage from "layouts/pages/6-categories/categories-page";
import AddNewtechnology from "layouts/pages/7-technologies/new-technology";
import EditTechnology from "layouts/pages/7-technologies/edit-technology";
import TechnologiesPage from "layouts/pages/7-technologies/technologies-page";
import VouchersPage from "layouts/pages/10-voucher/vouchers-page";
import EditTestimonial from "layouts/pages/12-testimonials/edit-testimonial";
import TestimonialsPage from "layouts/pages/12-testimonials/testimonials-page";
import AddNewTestimonial from "layouts/pages/12-testimonials/new-testimonial";
import ElectroSupportsPage from "layouts/pages/14-electronic-support/electronicSupport-page";
import CustomCampaignPage from "layouts/pages/13-custom-compaign/custom-campaign-page";
import UsersLists from "layouts/pages/01-usersList/usersList-page";
import EditUserList from "layouts/pages/01-usersList/edit-userList";
import AddNewWorkWithUsForm from "layouts/pages/15-work-with-us/new-work-with-us";
import WorkWithUsPage from "layouts/pages/15-work-with-us/work-with-us-page";
import NewsPage from "layouts/pages/16-news/news-page";
import AddNewNews from "layouts/pages/16-news/new-news";
import AddNewPromotion from "layouts/pages/19-promotions/new-promotion";
import PromotionsPage from "layouts/pages/19-promotions/promotions-page";
import AddCoupon from "layouts/pages/20-coupon/new-coupon";
import EditCoupon from "layouts/pages/20-coupon/edit-coupon";
import CouponsPage from "layouts/pages/20-coupon/coupons-page";
import AddNewLangage from "layouts/pages/18-langages/new-langage";
import EditLangage from "layouts/pages/18-langages/edit-langage";
import LangagesPage from "layouts/pages/18-langages/langages-page";
import UpdatePassword from "layouts/authentication/update-password/cover";
import AddNewElectronicSupport from "layouts/pages/14-electronic-support/new-electronicSupport";
import ElectronicSupportsPage from "layouts/pages/14-electronic-support/electronicSupport-page";
import EditElectronicSupport from "layouts/pages/14-electronic-support/edit-electronicSupport";
import AddNewTag from "layouts/pages/17-tags/new-tag";
import EditTag from "layouts/pages/17-tags/edit-tag";
import TagsPage from "layouts/pages/17-tags/tags-page";
import AddNewSolutionCategory from "layouts/pages/6-solution-categories/new-solution-category";
import SolutionCategoriesPage from "layouts/pages/6-solution-categories/solution-categories-page";
import EditSolutionCategory from "layouts/pages/6-solution-categories/edit-solution-categories";
import AddVoucher from "layouts/pages/10-voucher/new-voucher";
import EditVoucher from "layouts/pages/10-voucher/edit-voucher";
import { Logout } from "@mui/icons-material";
import EditUser from "layouts/pages/01-usersList/edit-userList";
import PayHistoryPage from "layouts/pages/11-pay-history/pay-history-page";
import AddNewSupport from "layouts/pages/21-support/new-support";
import SupportsPage from "layouts/pages/21-support/supports-page";
import EditSupport from "layouts/pages/21-support/edit-support";
import UpdateUserPassword from "layouts/authentication/update-user-password";
import ChangeUserPassword from "layouts/authentication/update-user-password";
import AskForCourseForm from "layouts/pages/22-ask-for-course/new-ask-for-course";
// import link from "assets/theme/components/link";

const handleLogout = () => {
  localStorage.removeItem("authToken");
  navigate("/authentification/sign-in-basic");
};
const logoutBtn = handleLogout;

const routes = [
  {
    type: "collapse",
    name: "Brooklyn Alice",
    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "profile-overview",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },
      {
        name: "Settings",
        key: "settings",
        route: "/pages/account/settings",
        component: <Settings />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
        icon: (
          <IconButton sx={navbarIconButton} size="small" disableRipple onClick={logoutBtn}>
            {" "}
          </IconButton>
        ),
      },
      // {
      //   name: "Logout",
      //   key: "logout",
      //   route: "/authentication/sign-in/basic",
      //   component: <SignInBasic />,
      // },
    ],
  },

  // Authentication
  {
    // type: "collapse",
    // name: "Authentication",
    // key: "authentication",
    // icon: <Icon fontSize="medium">content_paste</Icon>,
    // collapse: [
    //   {
    //     name: "sign-in",
    //     key: "basic",
    //     route: "/authentication/sign-in/basic",
    //     component: <SignInBasic />,
    //   },
    // {
    //   name: "Reset Password",
    //   key: "reset-password",
    //   collapse: [
    //     {
    //       name: "Cover",
    //       key: "cover",
    //       route: "/authentication/reset-password/cover",
    //       component: <ResetCover />,
    //     },
    //   ],
    // },
    // {
    //   name: "Update Password",
    //   key: "update-password",
    //   collapse: [
    //     {
    //       name: "Cover",
    //       key: "cover",
    //       route: "/authentication/update-password/cover",
    //       component: <UpdatePassword />,
    //     },
    //   ],
    // },
    // {
    //   name: "Update UserPassword",
    //   key: "update-user-password",
    //   collapse: [
    //     {
    //       name: "User",
    //       key: "user-password",
    //       route: "/authentication/update-user-password/",
    //       component: <ChangeUserPassword />,
    //     },
    //   ],
    // },
    // ],
  },
  {
    // name: "sign-in",
    // key: "basic",
    route: "/authentication/sign-in/basic",
    component: <SignInBasic />,
  },

  // -------------------

  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <PrivateRoute element={<Analytics />} />,
      },
    ],
  },

  // ------------Gestion de contenu-------------
  { type: "divider", key: "divider-1" },

  { type: "title", title: "Gestion de contenu", key: "title-gestionContenu" },

  //-------------- Users List---------------------
  {
    type: "collapse",
    name: "Users List",
    key: "Users List",
    icon: <GroupIcon fontSize="medium" />,
    collapse: [
      {
        name: "Users List Page",
        key: "usersList-page",
        route: "/dashboard/01-usersList/usersList-page",
        component: <UsersLists />,
      },
    ],
  },
  {
    // name: "Edit userList",
    // key: "edit-userList",
    route: "/dashboard/11-userList/edit-userList/:id",
    component: <EditUser />,
  },
  //-------------- Blogs---------------------
  {
    type: "collapse",
    name: "Blog",
    key: "blog",
    icon: <ArticleIcon fontSize="medium" />,
    collapse: [
      {
        name: "New Blog",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-blog",
        route: "/dashboard/blogs/new-blog",
        component: <AddNewBlog />,
      },
      {
        name: "Blogs Page",
        key: "blogs-page",
        route: "/dashboard/blogs/blogs-page",
        component: <BlogsPage />,
      },
    ],
  },
  {
    route: "/dashboard/blogs/edit-blog/:id",
    component: <EditBlog />,
  },
  //-------------- Home Slider---------------------
  {
    type: "collapse",
    name: "Home Slider",
    key: "homslider",
    icon: <SlideshowIcon fontSize="medium" />,
    collapse: [
      {
        name: "New homeSlider",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-homeSlider",
        route: "/dashboard/4-homeSlider/new-homeSlider",
        component: <AddNewHomeSlider />,
      },
      {
        name: "HomeSlider Page",
        key: "homeSliders-page",
        route: "/dashboard/4-homeSlider/homeSliders-page",
        component: <HomeSlidersPage />,
      },
    ],
  },
  {
    name: "Edit homeSlider",
    route: "/dashboard/4-homeSlider/edit-homeSlider/:id",
    component: <EditHomeSlider />,
  },
  //-------------- Portfolio---------------------
  {
    type: "collapse",
    name: "Portfolio",
    key: "portfolio",
    icon: <WorkIcon fontSize="medium" />,
    collapse: [
      {
        name: "New Portfolio",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-portfolio",
        route: "/dashboard/5-portfolio/new-portfolio",
        component: <AddNewPortfolio />,
      },
      {
        name: "Portfolio Page",
        key: "portfolios-page",
        route: "/dashboard/4-portfolio/portfolio-page",
        component: <PortfolioPage />,
      },
    ],
  },
  //-------------- News---------------------
  {
    type: "collapse",
    name: "News",
    key: "newa",
    icon: <PublicIcon fontSize="medium" />,
    collapse: [
      {
        name: "New News",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-news",
        route: "/dashboard/16-news/new-news",
        component: <AddNewNews />,
      },
      {
        name: "News Page",
        key: "news-page",
        route: "/dashboard/4-news/news-page",
        component: <NewsPage />,
      },
    ],
  },
  //-------------- Services---------------------
  {
    type: "collapse",
    name: "Services",
    key: "Services",
    icon: <DesignServicesIcon fontSize="medium" />,
    collapse: [
      {
        name: "New service",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-service",
        route: "/dashboard/8-services/new-service",
        component: <AddNewService />,
      },

      {
        name: "Services Page",
        key: "services-page",
        route: "/dashboard/8-services/services-page",
        component: <ServicesPage />,
      },
    ],
  },
  {
    // name: "Edit service",
    // key: "edit-service",
    route: "/dashboard/8-services/edit-service",
    component: <EditService />,
  },
  //-------------- Solutions---------------------
  {
    type: "collapse",
    name: "Solutions",
    key: "Solutions",
    icon: <LightbulbIcon fontSize="medium" />,
    collapse: [
      {
        name: "New solution",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-solution",
        route: "/dashboard/9-solutions/new-solution",
        component: <AddNewSolution />,
      },
      {
        name: "Solution Page",
        key: "solutions-page",
        route: "/dashboard/9-solutions/solutions-page",
        component: <SolutionsPage />,
      },
    ],
  },

  {
    // name: "Edit solution",
    // key: "edit-solution",
    route: "/dashboard/9-solutions/edit-solution/:id",
    component: <EditSolution />,
  },
  //-------------- Testimonials---------------------
  {
    type: "collapse",
    name: "Testimonials",
    key: "testimonials",
    icon: <ForumIcon fontSize="medium" />,
    collapse: [
      {
        name: "New Testimonial",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-testimonial",
        route: "/dashboard/12-Testimonials/new-testimonial",
        component: <AddNewTestimonial />,
      },
      {
        name: "Testimonials Page",
        key: "Testimonials-page",
        route: "/dashboard/12-Testimonials/Testimonials-page",
        component: <TestimonialsPage />,
      },
    ],
  },
  {
    route: "/dashboard/12-testimonials/edit-testimonial/:id",
    component: <EditTestimonial />,
  },
  //-------------- Electronic Support---------------------
  {
    type: "collapse",
    name: "Electronic Support",
    key: "electrosupport",
    icon: <SupportAgentIcon fontSize="medium" />,
    collapse: [
      {
        name: "New Electronic Support ",
        key: "new-electronicSupport",
        route: "/dashboard/14-electronic-support/new-electronicSupport",
        component: <AddNewElectronicSupport />,
      },
      {
        name: "Electronic Support Page",
        key: "electronicSupport-page",
        route: "/dashboard/14-electronic-support/electronicSupport-page",
        component: <ElectronicSupportsPage />,
      },
    ],
  },
  {
    // name: "edit Electronic Support ",
    // key: "edit-electronicSupport",
    route: "/dashboard/14-electronic-support/edit-electronicSupport",
    component: <EditElectronicSupport />,
  },

  //-------------- Support---------------------
  // {
  //   type: "collapse",
  //   name: "Support",
  //   key: "support",
  //   icon: <SupportAgentIcon fontSize="medium" />,
  //   collapse: [
  //     {
  //       name: "New Support ",
  //       key: "new-support",
  //       route: "/dashboard/21-support/new-support",
  //       component: <AddNewSupport />,
  //     },
  //     {
  //       name: "Support Page",
  //       key: "supports-page",
  //       route: "/dashboard/21-support/supports-page",
  //       component: <SupportsPage />,
  //     },
  //   ],
  // },
  // {
  //   // name: "edit Support ",
  //   // key: "edit-Support",
  //   route: "/dashboard/21-support/edit-support",
  //   component: <EditSupport />,
  // },
  //-------------- Work With Us---------------------
  {
    type: "collapse",
    name: "Work With Us",
    key: "work-with-us",
    icon: <WorkOutlineIcon fontSize="medium" />,
    collapse: [
      {
        name: "New Recrutement ",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-work-with-us",
        route: "/dashboard/15-Testimonials/new-work-with-us",
        component: <AddNewWorkWithUsForm />,
      },
      {
        name: "Recrutement Page",
        key: "work-with-us-page",
        route: "/dashboard/15-work-with-us/work-with-us-page",
        component: <WorkWithUsPage />,
      },
    ],
  },

  //-------------- Promotions ---------------------
  {
    type: "collapse",
    name: "Ask for Course",
    key: "ask-for-course",
    icon: <MenuBookIcon fontSize="medium" />,
    collapse: [
      {
        name: "Ask For Course",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-new-ask-for-course",
        route: "/dashboard/11-ask-for-course/new-ask-for-course",
        component: <AskForCourseForm />,
      },
      // {
      //   name: "Ask For Course Page",
      //   // key: "new-ask-for-course-page",
      //   route: "/dashboard/19-new-ask-for-course/new-ask-for-course-page",
      //   // component: <new-ask-for-course />,
      // },
    ],
  },
  {
    route: "/dashboard/19-promotions/edit-promotion",
    // component: <Editpromotion />,
  },

  { type: "divider", key: "divider-2" },

  { type: "title", title: "IT-Cert Academie", key: "IT-Cert Academie" },
  //-------------- Exam---------------------
  {
    type: "collapse",
    name: "Exam",
    key: "exam",
    icon: <SchoolIcon fontSize="medium" />,
    collapse: [
      {
        name: "New Exam",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-exam",
        route: "/dashboard/2-exams/new-exam",
        component: <AddNewExam />,
      },
      {
        name: "Exams Page",
        key: "exams-page",
        route: "/dashboard/2-exams/exams-page",
        component: <ExamsPage />,
      },
    ],
  },
  {
    route: "/dashboard/2-exams/edit-exam/:id",
    component: <EditExam />,
  },
  //-------------- Courses---------------------
  {
    type: "collapse",
    name: "Courses",
    key: "course",
    icon: <MenuBookIcon fontSize="medium" />,
    collapse: [
      {
        name: "New Course",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-course",
        route: "/dashboard/3-courses/new-course",
        component: <AddNewCourse />,
      },
      {
        name: "Course Page",
        key: "courses-page",
        route: "/dashboard/3-courses/courses-page",
        component: <CoursesPage />,
      },
    ],
  },
  {
    route: "/dashboard/3-courses/edit-course/:id",
    component: <EditCourse />,
  },
  //-------------- Custom Campaign---------------------
  {
    type: "collapse",
    name: "Custom Campaign",
    key: "custom-campaign-page",
    icon: <NotificationsActiveIcon fontSize="medium" />,
    collapse: [
      {
        name: "Custom Campaign Page",
        key: "custom-campaign-page",
        route: "/dashboard/17-custom-campaign/custom-campaign-page",
        component: <CustomCampaignPage />,
      },
    ],
  },
  { type: "divider", key: "divider-3" },

  { type: "title", title: "Archives", key: "archives" },
  //-------------- Categories---------------------
  {
    type: "collapse",
    name: "Categories",
    key: "category",
    icon: <CategoryIcon fontSize="medium" />,
    collapse: [
      {
        name: "New category",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-category",
        route: "/dashboard/6-categories/new-category",
        component: <AddNewCategory />,
      },
      {
        name: "Category Page",
        key: "categories-page",
        route: "/dashboard/6-categories/categories-page",
        component: <CategoriesPage />,
      },
    ],
  },
  {
    // name: "Edit category",
    // key: "edit-category",
    route: "/dashboard/6-categories/edit-category/:id",
    component: <EditCategory />,
  },
  //-------------- Categories---------------------
  {
    type: "collapse",
    name: "Solution Categories",
    key: "solution-category",
    icon: <BuildIcon fontSize="medium" />,
    collapse: [
      {
        name: "New Solution Category",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-SolutionCategory",
        route: "/dashboard/6-solution-categories/new-SolutionCategory",
        component: <AddNewSolutionCategory />,
      },
      {
        name: "Solution Category Page",
        key: "solution-categories-page",
        route: "/dashboard/6-solution-categories/solution-categories-page",
        component: <SolutionCategoriesPage />,
      },
    ],
  },
  {
    route: "/dashboard/6-solution-categories/edit-SolutionCategory",
    component: <EditSolutionCategory />,
  },
  //-------------- Technologies---------------------
  {
    type: "collapse",
    name: "Technologies",
    key: "Technology",
    icon: <ComputerIcon fontSize="medium" />,
    collapse: [
      {
        name: "New technology",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-technology",
        route: "/dashboard/7-technologies/new-technology",
        component: <AddNewtechnology />,
      },
      {
        name: "Technology Page",
        key: "technologies-page",
        route: "/dashboard/7-technologies/technologies-page",
        component: <TechnologiesPage />,
      },
    ],
  },
  {
    // name: "Edit technology",
    // key: "edit-technology",
    route: "/dashboard/7-technologies/edit-technology/:id",
    component: <EditTechnology />,
  },
  //-------------- Tags---------------------
  {
    type: "collapse",
    name: "Tags",
    key: "tag",
    icon: <LocalOfferIcon fontSize="medium" />,
    collapse: [
      {
        name: "New Tag",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-tag",
        route: "/dashboard/17-tags/new-tag",
        component: <AddNewTag />,
      },
      {
        name: "Tags Page",
        key: "tags-page",
        route: "/dashboard/17-tags/tags-page",
        component: <TagsPage />,
      },
    ],
  },
  {
    // name: "Edit tag",
    // key: "edit-tag",
    route: "/dashboard/17-tags/edit-tag/:id",
    component: <EditTag />,
  },
  //-------------- Langages---------------------
  {
    type: "collapse",
    name: "Langages",
    key: "langage",
    icon: <LanguageIcon fontSize="medium" />,
    collapse: [
      {
        name: "New Langage",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-langage",
        route: "/dashboard/18-langages/new-langage",
        component: <AddNewLangage />,
      },
      {
        name: "Langages Page",
        key: "langages-page",
        route: "/dashboard/18-langages/langages-page",
        component: <LangagesPage />,
      },
    ],
  },
  {
    // name: "Edit langage",
    // key: "edit-langage",
    route: "/dashboard/18-langages/edit-langage/:id",
    component: <EditLangage />,
  },
  //-------------- Promotions ---------------------
  {
    type: "collapse",
    name: "Promotions",
    key: "promotion",
    icon: <CampaignIcon fontSize="medium" />,
    collapse: [
      {
        name: "New promotion",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-promotion",
        route: "/dashboard/19-promotions/new-promotion",
        component: <AddNewPromotion />,
      },
      {
        name: "Promotions Page",
        key: "promotions-page",
        route: "/dashboard/19-promotions/promotions-page",
        component: <PromotionsPage />,
      },
    ],
  },
  {
    route: "/dashboard/19-promotions/edit-promotion",
    // component: <Editpromotion />,
  },
  //-------------- Voucher---------------------
  {
    type: "collapse",
    name: "Voucher",
    key: "voucher",
    icon: <CardGiftcardIcon fontSize="medium" />,
    collapse: [
      {
        name: "New Voucher ",
        key: "new-voucher",
        route: "/dashboard/10-vouchers/new-voucher",
        component: <AddVoucher />,
      },
      {
        name: "Vouchers Page",
        key: "vouchers-page",
        route: "/dashboard/10-vouchers/vouchers-page",
        component: <VouchersPage />,
      },
    ],
  },
  {
    route: "/dashboard/10-voucher/edit-voucher",
    component: <EditVoucher />,
  },
  //-------------- Coupon ---------------------
  {
    type: "collapse",
    name: "coupon",
    key: "coupon",
    icon: <SellIcon fontSize="medium" />,
    collapse: [
      {
        name: "New coupon",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-coupon",
        route: "/dashboard/20-coupons/new-coupon",
        component: <AddCoupon />,
      },
      {
        name: "coupons Page",
        key: "coupons-page",
        route: "/dashboard/20-coupons/coupons-page",
        component: <CouponsPage />,
      },
    ],
  },
  {
    // name: "Edit coupon",
    // key: "edit-coupon",
    route: "/dashboard/20-coupons/edit-coupon/:id",
    component: <EditCoupon />,
  },

  { type: "divider", key: "divider-4" },

  { type: "title", title: "Paiement ", key: "Paiement " },
  //-------------- Pay History---------------------
  {
    type: "collapse",
    name: "Pay History",
    key: "payHistory",
    icon: <HistoryIcon fontSize="medium" />,
    collapse: [
      {
        name: "Pay History Page",
        key: "payHistory-page",
        route: "/dashboard/16-payHistory-page/payHistory-page",
        component: <PayHistoryPage />,
      },
    ],
  },
];

export default routes;

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import { useDropzone } from "react-dropzone";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Autocomplete from "@mui/material/Autocomplete";
import { List, ListItem } from "@mui/material";

import Select from "react-select";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Switch from "@mui/material/Switch";
import Swal from "sweetalert2";
import { MenuItem, FormControl, InputLabel, TextField, Chip, Box, Typography } from "@mui/material";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function CourseDetails({
  courseData,
  updateCourseData,
  categories,
  technologies,
  supports,
  copons,
  canPassExam,
}) {
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [trainers, setTrainers] = useState([]);
    const [selectedTrainer, setSelectedTrainer] = useState(null);
    useEffect(() => {
      const fetchTrainers = async () => {
          try {
              const response = await axios.get(
                  `${process.env.REACT_APP_API_URL}/api/V2/user/users?isTeacher=true`
              );
              setTrainers(response.data);
          } catch (error) {
              console.error("Error fetching trainers:", error);
          }
      };

      fetchTrainers();
  }, []);


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default Enter behavior
    }
  };



  return (
    <Box>
      <Box>
        <MDTypography variant="h5">Course Details</MDTypography>
        <Box mt={3}>
          <Grid container spacing={3}>
            {/* Title Input */}
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Title"
                value={courseData.title}
                onChange={(e) => updateCourseData("title", e.target.value)}
              />
            </Grid>
            {/* Description Input */}
            <Grid item xs={12}>
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Description
              </MDTypography>
              <ReactQuill
                value={courseData.description}
                onChange={(value) => updateCourseData("description", value)}
              />
            </Grid>
            {/* Type Input */}
            <Grid item xs={12} sm={3}>
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Type
              </MDTypography>
              <Select
                options={[
                  { label: "Normal", value: "normal" },
                  { label: "Batch", value: "batch" },
                ]}
                value={{ label: courseData.type, value: courseData.type }}
                onChange={(selectedOption) => updateCourseData("type", selectedOption.value)}
                placeholder="Select Type"
              />
            </Grid>
            {/* OnSite Input */}
            <Grid item xs={12} sm={3}>
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                OnSite
              </MDTypography>
              <Select
                options={[
                  { label: "Empty", value: "empty" },
                  { label: "PersonVue", value: "personVue" },
                ]}
                value={{ label: courseData.onSite, value: courseData.onSite }}
                onChange={(selectedOption) => updateCourseData("onSite", selectedOption.value)}
                placeholder="Select OnSite Option"
              />
            </Grid>
            {/* Remote Input */}
            <Grid item xs={12} sm={3}>
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Remote
              </MDTypography>
              <Select
                options={[
                  { label: "OnVue", value: "onVue" },
                  { label: "Home", value: "home" },
                ]}
                value={{ label: courseData.remote, value: courseData.remote }}
                onChange={(selectedOption) => updateCourseData("remote", selectedOption.value)}
                placeholder="Select Remote Option"
              />
            </Grid>
            {/* Language Input */}
            <Grid item xs={12} sm={3}>
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Language
              </MDTypography>
              <Select
                options={[
                  { label: "English", value: "English" },
                  { label: "French", value: "French" },
                  { label: "Arabic", value: "Arabic" },
                  { label: "Spanish", value: "Spanish" },
                  { label: "German", value: "German" },
                ]}
                value={{ label: courseData.language, value: courseData.language }}
                onChange={(selectedOption) => updateCourseData("language", selectedOption.value)}
                placeholder="Select Language"
              />
            </Grid>
            {/* Level Input */}
            <Grid item xs={12} sm={3}>
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Level
              </MDTypography>
              <Select
                options={[
                  { label: "Beginner", value: "Beginner" },
                  { label: "Intermediate", value: "Intermediate" },
                  { label: "Professional", value: "Professional" },
                  { label: "Expert", value: "Expert" },
                  { label: "Master", value: "Master" },
                ]}
                value={{ label: courseData.level, value: courseData.level }}
                onChange={(selectedOption) => updateCourseData("level", selectedOption.value)}
                placeholder="Select Level"
              />
            </Grid>
            {/* Technologies Input */}
            <Grid item xs={12} sm={3}>
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Technologies
              </MDTypography>
              <Select
                options={technologies.map((item) => ({
                  label: item.name,
                  value: item._id,
                }))}
                value={technologies
                  .filter((tech) => courseData.technologies?.includes(tech._id))
                  .map((tech) => ({ label: tech.name, value: tech._id }))}
                onChange={(selectedOptions) =>
                  updateCourseData(
                    "technologies",
                    selectedOptions ? selectedOptions.map((opt) => opt.value) : []
                  )
                }
                isMulti
                placeholder="Select Technologies"
              />
            </Grid>
            {/* Categories Input */}
            <Grid item xs={12} sm={3}>
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Categories
              </MDTypography>
              <Select
                options={categories.map((item) => ({
                  label: item.name,
                  value: item._id,
                }))}
                value={categories
                  .filter((cat) => courseData.categories?.includes(cat._id))
                  .map((cat) => ({ label: cat.name, value: cat._id }))}
                onChange={(selectedOptions) =>
                  updateCourseData(
                    "categories",
                    selectedOptions ? selectedOptions.map((opt) => opt.value) : []
                  )
                }
                isMulti
                placeholder="Select Categories"
              />
            </Grid>
            {/* canPassExam */}
            <Grid item xs={12} sm={3}>
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                canPassExam
              </MDTypography>
              {Array.isArray(canPassExam) && (
                <Select
                  options={canPassExam.map((item) => ({
                    label: item.title,
                    value: item._id,
                  }))}
                  value={
                    courseData.canPassExam
                      ? canPassExam
                          .filter((cat) => courseData.canPassExam.includes(cat._id))
                          .map((cat) => ({ label: cat.title, value: cat._id }))
                      : []
                  }
                  onChange={(selectedOptions) =>
                    updateCourseData(
                      "canPassExam",
                      selectedOptions ? selectedOptions.map((opt) => opt.value) : []
                    )
                  }
                  isMulti
                  placeholder="Select canPassExam"
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Supports
              </MDTypography>
              {Array.isArray(supports) && (
                <Select
                  options={supports.map((item) => ({
                    label: item.title,
                    value: item._id,
                  }))}
                  value={
                    courseData.supports
                      ? supports
                          .filter((cat) => courseData.supports.includes(cat._id))
                          .map((cat) => ({ label: cat.title, value: cat._id }))
                      : []
                  }
                  onChange={(selectedOptions) =>
                    updateCourseData(
                      "supports",
                      selectedOptions ? selectedOptions.map((opt) => opt.value) : []
                    )
                  }
                  isMulti
                  placeholder="Select Supports"
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Copon
              </MDTypography>
              {Array.isArray(copons) && (
                <Select
                  options={copons.map((item) => ({
                    label: item.code,
                    value: item._id,
                  }))}
                  value={
                    courseData.copon
                      ? {
                          label: copons.find((copon) => copon._id === courseData.copon)?.code,
                          value: courseData.copon,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    updateCourseData("copon", selectedOption ? selectedOption.value : null)
                  }
                  placeholder="Select Copon"
                />
              )}
            </Grid>
            {/* Price */}
            <Grid item xs={12} sm={2}>
              <FormField
                type="number"
                label="Price"
                value={courseData.price}
                onChange={(e) => updateCourseData("price", e.target.value)}
              />
            </Grid>
            {/* Duration */}
            <Grid item xs={12} sm={2}>
              <FormField
                type="number"
                label="Duration"
                value={courseData.duration}
                onChange={(e) => updateCourseData("duration", e.target.value)}
              />
            </Grid>
            {/* Sum of Site */}
            <Grid item xs={12} sm={2}>
              <FormField
                type="number"
                label="Sum of Site"
                value={courseData.sumOfSite}
                onChange={(e) => updateCourseData("sumOfSite", e.target.value)}
              />
            </Grid>
            {/* Reserved Places */}
            <Grid item xs={12} sm={2}>
              <FormField
                type="number"
                label="Reserved Places"
                value={courseData.reservedPlaces}
                onChange={(e) => updateCourseData("reservedPlaces", e.target.value)}
              />
            </Grid>
            {/* Lectures */}
            <Grid item xs={12} sm={3}>
              <FormField
                type="number"
                label="Lectures"
                value={courseData.lectures}
                onChange={(e) => updateCourseData("lectures", e.target.value)}
              />
            </Grid>{" "}
            {/* Objectifs */}
            <Grid item xs={12} sm={4}>
              <Box mt={2}>
                <Typography component="label" variant="button" fontWeight="regular" color="text">
                  Objectifs
                </Typography>
                <List>
                  {courseData.objectifs?.map((objectif, index) => (
                    <ListItem key={index} disablePadding>
                      <Chip
                        label={objectif}
                        onDelete={() =>
                          updateCourseData(
                            "objectifs",
                            courseData.objectifs.filter((obj) => obj !== objectif)
                          )
                        }
                        color="primary"
                        sx={{ margin: 0.8 }}
                      />
                    </ListItem>
                  ))}
                </List>
                <TextField
                  fullWidth
                  label="Add Objectifs"
                  sx={{ margin: 1.2 }}
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && inputValue.trim() !== "") {
                      updateCourseData("objectifs", [
                        ...(courseData.objectifs || []),
                        inputValue.trim(),
                      ]);
                      setInputValue("");
                    }
                  }}
                  placeholder="Press Enter to add an objectif"
                  variant="outlined"
                  multiline
                  rows={3}
                />
              </Box>
            </Grid>
            {/* Prerequisites */}
            <Grid item xs={12} sm={4}>
              <Box mt={2}>
                <Typography component="label" variant="button" fontWeight="regular" color="text">
                  Prerequisites
                </Typography>
                <List>
                  {courseData.prerequisites?.map((prerequisite, index) => (
                    <ListItem key={index} disablePadding>
                      <Chip
                        label={prerequisite}
                        onDelete={() =>
                          updateCourseData(
                            "prerequisites",
                            courseData.prerequisites.filter((prereq) => prereq !== prerequisite)
                          )
                        }
                        color="primary"
                        sx={{ margin: 0.8 }}
                      />
                    </ListItem>
                  ))}
                </List>
                <TextField
                  fullWidth
                  sx={{ margin: 1.2 }}
                  label="Add Prerequisites"
                  value={inputValue2}
                  onChange={(e) => setInputValue2(e.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && inputValue2.trim() !== "") {
                      updateCourseData("prerequisites", [
                        ...(courseData.prerequisites || []),
                        inputValue2.trim(),
                      ]);
                      setInputValue2("");
                    }
                  }}
                  placeholder="Press Enter to add a prerequisite"
                  variant="outlined"
                  multiline
                  rows={3}
                />
              </Box>
            </Grid>
            {/* IsActive  inSlider*/}
            <Grid item xs={12} sm={6}>
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                IsActive
              </MDTypography>
              <Switch
                checked={courseData.isActive}
                onChange={(e) => updateCourseData("isActive", e.target.checked)}
                color="primary"
              />
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                inSlider
              </MDTypography>
              <Switch
                checked={courseData.inSlider}
                onChange={(e) => updateCourseData("inSlider", e.target.checked)}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                    Trainer
                </MDTypography>
                <Select
    options={trainers.map((trainer) => ({
        label: trainer.fullName,
        value: trainer._id,
    }))}
    value={selectedTrainer ? {label: trainers.find(trainer => trainer._id === selectedTrainer)?.fullName, value:selectedTrainer}: null}
    onChange={(selectedOption) => {
        setSelectedTrainer(selectedOption ? selectedOption.value : null);
        updateCourseData("formateur", selectedOption?.value || null);
    }}
    placeholder="Select Trainer"
    isClearable={true}
    isMulti={false}
/>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

CourseDetails.propTypes = {
  courseData: PropTypes.object.isRequired,
  updateCourseData: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  technologies: PropTypes.array.isRequired,
  supports: PropTypes.array.isRequired,
  copons: PropTypes.array.isRequired, 
  canPassExam: PropTypes.array.isRequired,
};

function Media({ courseData, updateCourseData }) {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      updateCourseData("mainlmg", acceptedFiles[0]);
    },
    accept: "image/*",
  });

  const handleImageClick = () => {
    document.getElementById("file-input").click();
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      updateCourseData("mainlmg", e.target.files[0]);
    }
  };

  return (
    <MDBox>
      {/* Media Header */}
      <MDBox mt={3}>
        <MDBox>
          <MDTypography variant="h5" fontWeight="medium" gutterBottom>
            Media
          </MDTypography>
          <MDBox mt={3}>
            {/* Label */}
            <MDBox mb={1} ml={0.5}>
              <MDTypography
                component="label"
                variant="subtitle1"
                fontWeight="light"
                color="textSecondary"
              >
                Main Image
              </MDTypography>
            </MDBox>

            {/* Drag-and-drop or uploaded image */}
            {!courseData.mainlmg && (
              <MDBox
                {...getRootProps()}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                border="2px dashed"
                borderRadius="8px"
                borderColor="grey.400"
                bgcolor="grey.100"
                py={4}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "grey.200",
                  },
                  transition: "background-color 0.2s ease-in-out",
                }}
              >
                <input {...getInputProps()} />
                <MDTypography variant="button" fontWeight="regular" color="textSecondary" mb={1}>
                  Drag & drop an image here or
                </MDTypography>
                <MDButton variant="contained" color="primary">
                  Browse Files
                </MDButton>
              </MDBox>
            )}

            {/* Display uploaded image */}
            {courseData.mainlmg && courseData.mainlmg instanceof File && (
              <MDBox
                mt={3}
                textAlign="center"
                onClick={handleImageClick} // Add click handler to upload a new image
                sx={{ cursor: "pointer" }} // Add pointer cursor for interactivity
              >
                <img
                  src={URL.createObjectURL(courseData.mainlmg)}
                  alt="Course Main Image"
                  style={{
                    width: "100%",
                    maxWidth: "150px",
                    marginTop: "10px",
                    borderRadius: "8px",
                    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  }}
                />
                <MDTypography variant="body2" color="textSecondary" mt={2}>
                  Click to upload a new image
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      </MDBox>

      {/* Hidden file input for uploading images */}
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="file-input"
      />
    </MDBox>
  );
}

Media.propTypes = {
  courseData: PropTypes.object.isRequired,
  updateCourseData: PropTypes.func.isRequired,
};

function AddNewCourse() {
  const navigate = useNavigate();
  const [courseData, setCourseData] = useState({
    title: "",
    description: "",
    categories: [],
    technologies: [],
    prerequisites: [],
    mainlmg: null,
    type: "normal",
    duration: 1,
    isActive: true,
    inSlider: true,
    onSite: "Empty",
    remote: "Home",
    price: 100,
    sumOfSite: 100,
    language: "English",
    reservedPlaces: 100,
    copon: [],
    level: "Beginner",
    objectifs: [],
    lectures: 1,
    canPassExam: [],
    supports: [],
  });

  const [technologies, setTechnologies] = useState([]);
  const [categories, setCategories] = useState([]);
  const [supports, setSupports] = useState([]);
  const [copons, setcopons] = useState([]);
  const [canPassExam, setcanPassExam] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const technologyResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v2/technology/technologies`
        );
        setTechnologies(technologyResponse.data.data || []);

        const canPassExamResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/V2/exame/all-exame`
        );
        setcanPassExam(canPassExamResponse.data.data || []);

        const categoryResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v2/category/categories`
        );
        setCategories(categoryResponse.data.data || []);

        const supportResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/V2/slectronicsupport/all`
        );
        setSupports(supportResponse.data.electronicSupports || []);

        const coponResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v2/coupon/coupons`
        );
        setcopons(coponResponse.data.data || []);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    fetchData();
  }, []);

  const updateCourseData = (field, value) => {
    setCourseData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();

      for (const [key, value] of Object.entries(courseData)) {
        
        if (key === "formateur") {
          formData.append(key, value);

      } else if (
          key === "categories" ||
          key === "supports" ||
          key === "technologies" ||
          key === "canPassExam" ||
          key === "copon"
      ) {
          let parsedValue = value;

          if (typeof value === "string" && value.startsWith("[") && value.endsWith("]")) {
            try {
              parsedValue = JSON.parse(value);
            } catch (error) {
              console.error(`Error parsing ${key}:`, error);
              Swal.fire({
                title: "Parsing Error",
                text: `Failed to parse ${key}. Please check the input format.`,
                icon: "error",
                confirmButtonText: "OK",
              });
              return;
            }
          } else if (typeof value === "string") {
            parsedValue = [value];
          }

          formData.append(key, JSON.stringify(parsedValue));
        } else if (key === "mainlmg") {
          if (courseData.mainlmg) {
            formData.append("mainlmg", courseData.mainlmg);
          }
        } else {
          formData.append(key, value);
        }
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/V2/course/add-course`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        Swal.fire({
          title: "Success!",
          text: "Course added successfully!",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/dashboard/3-courses/courses-page");
        });
      }
    } catch (error) {
      console.error("Error adding course:", error.response?.data || error.message);
      const errorMessage =
        error.response?.data?.message || "Failed to add course. Please try again.";
      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Add New Course
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          This information will describe more about the Course .
        </MDTypography>
      </MDBox>
      <MDBox component="form" onSubmit={handleSubmit}>
        <MDBox mt={4}>
          <Card>
            <MDBox p={3}>
              <CourseDetails
                courseData={courseData}
                updateCourseData={updateCourseData}
                categories={categories}
                technologies={technologies}
                supports={supports}
                copons={copons}
                canPassExam={canPassExam}
              />
            </MDBox>
          </Card>
        </MDBox>
        <MDBox mt={2}>
          <Card>
            <MDBox p={2}>
              <Media courseData={courseData} updateCourseData={updateCourseData} />
            </MDBox>
          </Card>
        </MDBox>

        <MDBox mt={4} display="flex" justifyContent="flex-end">
          <MDButton
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            sx={{
              borderRadius: "8px",
              textTransform: "capitalize",
              fontWeight: "medium",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                backgroundColor: "primary.dark",
                transform: "translateY(-2px)",
                boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            Add Course
          </MDButton>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddNewCourse;

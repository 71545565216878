import React, { useState } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function AddVoucher() {
  const navigate = useNavigate();
  const [voucherData, setVoucherData] = useState({
    name: "",
    description: "",
    fromExam: "",
    isActive: true,
    isValid: true,
    expiredDate: "",
  });
  const [errors, setErrors] = useState({});

  const updateVoucherData = (field, value) => {
    setVoucherData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async () => {
    if (!voucherData.name || !voucherData.description || !voucherData.fromExam) {
      setErrors({
        name: "Name is required",
        description: "Description is required",
        fromExam: "Exam is required",
      });
      return;
    }

    const voucherFormData = {
      name: voucherData.name,
      description: voucherData.description,
      fromExam: voucherData.fromExam,
      isActive: voucherData.isActive,
      isValid: voucherData.isValid,
      expiredDate: voucherData.expiredDate,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v2/voucher/add-voucher`,
        voucherFormData
      );

      Swal.fire({
        title: "Success!",
        text: "The voucher has been added successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      navigate("/dashboard/voucher/voucher-list");
      console.log("Response:", response.data);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to add the voucher.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Edit Voucher
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          This information will describe the new voucher.
        </MDTypography>
      </MDBox>
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5" fontWeight="medium">
                  Voucher Details
                </MDTypography>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="Name"
                      value={voucherData.name}
                      onChange={(e) => updateVoucherData("name", e.target.value)}
                    />
                    {errors.name && <MDTypography color="error">{errors.name}</MDTypography>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="Description"
                      value={voucherData.description}
                      onChange={(e) => updateVoucherData("description", e.target.value)}
                    />
                    {errors.description && (
                      <MDTypography color="error">{errors.description}</MDTypography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="Exam ID"
                      value={voucherData.fromExam}
                      onChange={(e) => updateVoucherData("fromExam", e.target.value)}
                    />
                    {errors.fromExam && (
                      <MDTypography color="error">{errors.fromExam}</MDTypography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="date"
                      label="Expiration Date"
                      value={voucherData.expiredDate}
                      onChange={(e) => updateVoucherData("expiredDate", e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      IsActive
                    </MDTypography>
                    <Switch
                      checked={voucherData.isActive}
                      onChange={(e) => updateVoucherData("isActive", e.target.checked)}
                      color="primary"
                    />
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      isValid
                    </MDTypography>
                    <Switch
                      checked={voucherData.isValid}
                      onChange={(e) => updateVoucherData("isValid", e.target.checked)}
                      color="primary"
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
            <MDBox mt={3} display="flex" justifyContent="flex-end">
              <MDButton variant="contained" color="primary" onClick={handleSubmit}>
                Publish
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddVoucher;

import { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Swal from "sweetalert2";

function PayHistoryPage() {
  const [payHistory, setPayHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPayHistory = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/V2/payHistory`);
      console.log("API Response:", response.data);
      setPayHistory(response.data.data || []);
      setLoading(false);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Failed to fetch payment history from the server.",
        icon: "error",
        confirmButtonText: "OK",
      });
      setLoading(false);
      console.error("Error fetching payment history:", error);
    }
  };

  useEffect(() => {
    fetchPayHistory();
  }, []);

  const payHistoryTableData = {
    columns: [
      { Header: "User", accessor: "fromUser", align: "left" },
      { Header: "Selected Courses", accessor: "selectedCourses", align: "left" },
      { Header: "Total Amount", accessor: "totalAmount", align: "right" },
    ],
    rows: Array.isArray(payHistory)
      ? payHistory.map((record) => ({
          fromUser: record.fromUser || "N/A",
          selectedCourses: Array.isArray(record.selectedCourses)
            ? record.selectedCourses.join(", ")
            : "N/A",
          totalAmount: `$${record.totalAmount?.toFixed(2)}`,
        }))
      : [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          Payment History
        </MDTypography>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={payHistoryTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PayHistoryPage;

import { useState } from "react";
// import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function VoucherStatus({ isActive }) {
  return (
    <MDBadge
      variant="contained"
      color={isActive ? "success" : "error"}
      badgeContent={isActive ? "Active" : "Inactive"}
    />
  );
}

function VoucherValidation({ isValid }) {
  return (
    <MDBadge
      variant="contained"
      color={isValid ? "success" : "error"}
      badgeContent={isValid ? "valid" : "Invalid"}
    />
  );
}

VoucherStatus.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

VoucherValidation.propTypes = {
  isValid: PropTypes.bool.isRequired,
};

function VouchersPage() {
  const navigate = useNavigate();

  const [vouchers, setVouchers] = useState([
    {
      id: 1,
      name: "React Basics",
      description: "React Basics",
      fromExam: ["React"],
      isActive: true,
      isValid: true,
      ExpiredDate: Date(2011 - 2 - 2),
    },
    {
      id: 2,
      name: "Healthy Living",
      description: "React Basics",
      fromExam: ["Health"],
      isActive: false,
      isValid: false,
      ExpiredDate: Date(2011 - 2 - 5),
    },
  ]);

  const addvoucher = (newvoucher) =>
    setVouchers((prev) => [...prev, { id: Date.now(), ...newvoucher }]);

  const deleteVoucher = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This voucher will be permanently deleted!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/V2/voucher/${id}`);
        setVouchers((prev) => prev.filter((voucher) => voucher._id !== id));
        Swal.fire("Deleted!", "The voucher has been deleted.", "success");
      }
    } catch (error) {
      console.error("Error deleting voucher:", error);
      Swal.fire("Error!", "Failed to delete the voucher. Please try again.", "error");
    }
  };

  const editVoucher = (id) => {
    navigate(`/dashboard/10-voucher/edit-voucher/`); //${id}
  };

  const vouchersTableData = {
    columns: [
      { Header: "name", accessor: "name", align: "left" },
      { Header: "description", accessor: "description", align: "left" },
      { Header: "from exam", accessor: "fromExam", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "validation", accessor: "validation", align: "center" },
      { Header: "Expired Date", accessor: "Expired Date", align: "center" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],
    rows: vouchers.map((voucher) => ({
      name: voucher.name,
      description: voucher.description,
      fromExam: voucher.fromExam.join(", "),
      status: <VoucherStatus isActive={voucher.isActive} />,
      validation: <VoucherValidation isValid={voucher.isValid} />,
      ExpiredDate: voucher.ExpiredDate,
      actions: (
        <MDBox display="flex" justifyContent="center" gap={1}>
          <Tooltip title="Edit voucher">
            <IconButton color="info" onClick={() => editVoucher(voucher._id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete voucher">
            <IconButton color="error" onClick={() => deleteVoucher(voucher._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          All Vouchers
        </MDTypography>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={vouchersTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default VouchersPage;

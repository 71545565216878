import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function AddNewSolutionCategory() {
  const navigate = useNavigate();
  const [solutionCategoryData, setSolutionCategoryData] = useState({
    title: "",
    category: [],
    description: "",
    mainImg: null,
  });
  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});

  const updateSolutionCategoryData = (field, value) => {
    setSolutionCategoryData((prev) => ({ ...prev, [field]: value }));
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v2/category/categories`
        );
        setCategories(categoryResponse.data.data || []);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    fetchData();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSolutionCategoryData((prevState) => ({
        ...prevState,
        mainImg: file,
      }));
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("title", solutionCategoryData.title);
    formData.append("category", solutionCategoryData.category);
    formData.append("description", solutionCategoryData.description);
    formData.append("mainImg", solutionCategoryData.mainImg);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/V2/categories/add-category`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      Swal.fire({
        title: "Success!",
        text: "The category has been added successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      navigate("/dashboard/categories");
      console.log("Response:", response.data);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to add the category.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Add New Solution Category
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          This information will describe more about the Solution Category.
        </MDTypography>
      </MDBox>
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5" fontWeight="medium">
                  Solution Category Details
                </MDTypography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="Title"
                      value={solutionCategoryData.title}
                      onChange={(e) => updateSolutionCategoryData("title", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Categories
                    </MDTypography>
                    {Array.isArray(categories) && (
                      <Select
                        options={categories.map((item) => ({
                          label: item.name,
                          value: item._id,
                        }))}
                        value={
                          solutionCategoryData.categories
                            ? categories
                                .filter((cat) => solutionCategoryData.categories.includes(cat._id))
                                .map((cat) => ({ label: cat.name, value: cat._id }))
                            : []
                        }
                        onChange={(selectedOptions) =>
                          updateSolutionCategoryData(
                            "categories",
                            selectedOptions ? selectedOptions.map((opt) => opt.value) : []
                          )
                        }
                        isMulti
                        placeholder="Select Categories"
                      />
                    )}
                    {errors.categories && (
                      <MDTypography color="error">{errors.categories}</MDTypography>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <FormField
                      type="text"
                      label="Description"
                      value={solutionCategoryData.description}
                      onChange={(e) => updateSolutionCategoryData("description", e.target.value)}
                    />
                  </Grid>
                </Grid>
                <MDBox mt={4}>
                  <MDTypography
                    variant="subtitle2"
                    fontWeight="regular"
                    color="textSecondary"
                    mb={1}
                  >
                    Main Image
                  </MDTypography>
                  {!solutionCategoryData.mainImg && (
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      border="2px dashed"
                      borderColor="grey.400"
                      bgcolor="grey.100"
                      borderRadius="8px"
                      py={4}
                      sx={{
                        cursor: "pointer",
                        "&:hover": { backgroundColor: "grey.200" },
                        transition: "background-color 0.3s ease-in-out",
                      }}
                      onClick={() => document.getElementById("file-input").click()}
                    >
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="textSecondary"
                        mb={1}
                      >
                        Drag & drop an image here, or
                      </MDTypography>
                      <MDButton
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering the outer MDBox click.
                          document.getElementById("file-input").click();
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        Browse Files
                      </MDButton>
                    </MDBox>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="file-input"
                  />
                  {solutionCategoryData.mainImg && (
                    <MDBox
                      mt={3}
                      textAlign="center"
                      sx={{ cursor: "pointer" }}
                      onClick={() => document.getElementById("file-input").click()}
                    >
                      <MDTypography variant="body2" color="textSecondary">
                        Uploaded Image:
                      </MDTypography>
                      <img
                        src={URL.createObjectURL(solutionCategoryData.mainImg)}
                        alt="Category Main"
                        style={{
                          width: "100%",
                          maxWidth: "150px",
                          marginTop: "10px",
                          borderRadius: "8px",
                        }}
                      />
                      <MDTypography variant="body2" color="textSecondary" mt={2}>
                        Click to upload a new image
                      </MDTypography>
                    </MDBox>
                  )}

                  {errors.mainImg && <MDTypography color="error">{errors.mainImg}</MDTypography>}
                </MDBox>
              </MDBox>
            </Card>
            <MDBox mt={3} display="flex" justifyContent="flex-end">
              <MDButton variant="contained" color="primary" onClick={handleSubmit}>
                Publish
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddNewSolutionCategory;

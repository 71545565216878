import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import { Grid, Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import PropTypes from "prop-types";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};
const EditSolution = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [solutionData, setSolutionData] = useState({
    title: "",
    description: "",
    categories: [],
    mainlmg: null,
    whyChoosUs: "",
  });
  const [categories, setCategories] = useState([]);
  const [currentImg, setCurrentImg] = useState(null);

  const updateSolutionData = (field, value) => {
    setSolutionData((prev) => ({ ...prev, [field]: value }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSolutionData((prev) => ({
        ...prev,
        mainlmg: file,
      }));
    }
  };

  useEffect(() => {
    const fetchSolutionData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/V2/solution/${id}`);
        const data = response.data;

        setSolutionData({
          title: data.title || "",
          description: data.description || "",
          categories: data.categories.map((cat) => cat._id) || [],
          mainlmg: null, // Reset the image file field
          whyChoosUs: data.whyChoosUs || "",
        });

        setCurrentImg(data.mainlmg?.url || "");
        setCategories(data.categories || []);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Failed to fetch solution data.",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error("Error fetching solution data:", error);
      }
    };

    fetchSolutionData();
  }, [id]);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("title", solutionData.title);
    formData.append("description", solutionData.description);
    formData.append("categories", JSON.stringify(solutionData.categories));
    formData.append("whyChoosUs", solutionData.whyChoosUs);
    if (solutionData.mainlmg) {
      formData.append("mainlmg", solutionData.mainlmg);
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/V2/solution/${id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      Swal.fire({
        title: "Success!",
        text: "The solution has been updated successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      navigate("/dashboard/9-solutions/solutions-page");
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to update the solution.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="center">
        <MDTypography variant="h3">Edit Solution</MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          Modify the information about the solution.
        </MDTypography>
      </MDBox>
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5" fontWeight="medium">
                  Edit Solution
                </MDTypography>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        type="text"
                        label="Title"
                        value={solutionData.title}
                        onChange={(e) => updateSolutionData("title", e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="Description"
                      value={solutionData.description}
                      onChange={(e) => updateSolutionData("description", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label="Why Choose Us"
                      type="text"
                      placeholder="Why Choose Us"
                      value={solutionData.whyChoosUs}
                      onChange={(e) =>
                        updateSolutionData(
                          "whyChoosUs",
                          e.target.value.split(",").map((s) => s.trim())
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography component="label" fontWeight="">
                      Categories
                    </MDTypography>
                    <Select
                      isMulti
                      options={categories.map((cat) => ({
                        value: cat._id,
                        label: cat.name,
                      }))}
                      value={solutionData.categories.map((catId) => ({
                        value: catId,
                        label: categories.find((cat) => cat._id === catId)?.name || catId,
                      }))}
                      onChange={(selected) =>
                        updateSolutionData(
                          "categories",
                          selected.map((item) => item.value)
                        )
                      }
                    />
                  </Grid>

                  <Grid item xs={12} spacing={2}>
                    <MDTypography component="label" fontWeight="">
                      Background Image
                    </MDTypography>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      border="2px dashed"
                      borderColor="grey.400"
                      bgcolor="grey.100"
                      borderRadius="4px"
                      py={4}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        id="file-input"
                      />

                      {currentImg && !solutionData.mainlmg && (
                        <img
                          src={currentImg}
                          alt="Current Solution Background"
                          style={{
                            width: "100%",
                            maxWidth: "150px",
                            marginTop: "10px",
                            borderRadius: "8px",
                          }}
                        />
                      )}
                      {solutionData.mainlmg && (
                        <img
                          src={URL.createObjectURL(solutionData.mainlmg)}
                          alt="Updated Solution Background"
                          style={{
                            width: "100%",
                            maxWidth: "150px",
                            marginTop: "10px",
                            borderRadius: "8px",
                          }}
                        />
                      )}
                      <MDButton
                        spacing={8}
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={() => document.getElementById("file-input").click()}
                      >
                        Browse Files
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
            <MDBox mt={3} display="flex" justifyContent="flex-end">
              <MDButton variant="contained" color="primary" onClick={handleSubmit}>
                Save Changes
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditSolution;

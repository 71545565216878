import React from "react";
import { Route, Navigate } from "react-router-dom";
import PropTypes from "prop-types";

const PrivateRoute = ({ element, ...rest }) => {
  const token = localStorage.getItem("authToken");

  return token ? element : <Navigate to="/authentication/sign-in/basic" />;
};

PrivateRoute.propTypes = {
  element: PropTypes.node.isRequired,
};

export default PrivateRoute;

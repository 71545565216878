import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Swal from "sweetalert2";

function PromotionsPage() {
  const navigate = useNavigate();
  const [promotions, setPromotions] = useState([]);

  // Fetching promotions data from the API
  useEffect(() => {
    axios
      .get("${process.env.REACT_APP_API_URL}/api/V2/promotion/all-promotions")
      .then((response) => {
        const fetchedPromotions = response.data.data;
        setPromotions(fetchedPromotions);
      })
      .catch((error) => {
        console.error("There was an error fetching the promotions data!", error);
      });
  }, []);

  // Function to delete a promotion from the database and the state
  // const deleteOnePromotion = async (id) => {
  //   try {
  //     // Show SweetAlert2 confirmation dialog
  //     const result = await Swal.fire({
  //       title: "Are you sure?",
  //       text: "This promotion will be permanently deleted!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, delete it!",
  //     });

  //     if (result.isConfirmed) {
  //       // Proceed with the deletion
  //       await axios.delete(`${process.env.REACT_APP_API_URL}/api/V2/promotion/all-promotions/${id}`);

  //       // Update the local state to remove the deleted promotion
  //       setPromotions((prev) => prev.filter((promotion) => promotion._id !== id));

  //       // Show success alert
  //       Swal.fire("Deleted!", "The promotion has been deleted.", "success");
  //     }
  //   } catch (error) {
  //     console.error("There was an error deleting the promotion!", error);

  //     // Show error alert
  //     Swal.fire("Error!", "Failed to delete the promotion. Please try again.", "error");
  //   }
  // };

  // Table data for displaying the promotions
  const promotionsTableData = {
    columns: [
      { Header: "User ID", accessor: "userID", align: "left" },
      { Header: "Course ID", accessor: "courseID", align: "left" },
      // { Header: "Actions", accessor: "actions", align: "center" },
    ],
    rows: promotions.map((promotion) => ({
      userID: promotion.userID,
      courseID: promotion.courseID,
      // actions: (
      //   <MDBox display="flex" justifyContent="center" gap={1}>
      //     <Tooltip title="Delete Promotion">
      //       <IconButton color="error" onClick={() => deleteOnePromotion(promotion._id)}>
      //         <DeleteIcon />
      //       </IconButton>
      //     </Tooltip>
      //   </MDBox>
      // ),
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          All Promotions
        </MDTypography>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={promotionsTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PromotionsPage;

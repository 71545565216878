import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import ReactQuill from "react-quill";
import Select from "react-select";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Swal from "sweetalert2";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import defaultImage from "assets/images/blog/default-image.jpg";

function FormField({ label, ...rest }) {
  return (
    <MDBox mb={2}>
      <MDInput {...rest} variant="standard" label={label} fullWidth />
    </MDBox>
  );
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function EditBlog() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editorValue, setEditorValue] = useState("");
  const [mainImage, setMainImage] = useState(defaultImage);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [blog, setBlog] = useState(null);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const reader = new FileReader();
      reader.onload = (e) => setMainImage(e.target.result);
      reader.readAsDataURL(acceptedFiles[0]);
    },
  });

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v2/category/categories`
        );
        console.log("Raw categories data:", response.data.data);

        const fetchedCategories = response.data.data.map((category) => ({
          value: category._id,
          label: category.name,
        }));
        setCategories(fetchedCategories);
        console.log("Formatted categories:", fetchedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const blogResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/V2/blog/all-blog/${id}`
        );
        const blogData = blogResponse.data;

        const category =
          blogData.category && Array.isArray(blogData.category) && blogData.category.length > 0
            ? blogData.category[0]
            : null;

        const tags = blogData.tags && Array.isArray(blogData.tags) ? blogData.tags : [];

        setBlog(blogData);
        setMainImage(
          blogData.mainlmg && blogData.mainlmg.url ? blogData.mainlmg.url : defaultImage
        );
        setEditorValue(blogData.description || "");
        setSelectedCategory(category ? { value: category._id, label: category.name } : null);
        setSelectedTags(tags.map((tag) => ({ value: tag._id, label: tag.name })));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog:", error);
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleSave = async () => {
    try {
      const formData = new FormData();
      formData.append("title", blog.title);
      formData.append("author", blog.author);
      formData.append("description", editorValue || "");
      formData.append("category", selectedCategory?.value || "");

      selectedTags.forEach((tag) => {
        formData.append("tags[]", tag.value);
      });

      formData.append("isActive", blog.isActive ? "true" : "false");

      if (mainImage && mainImage.startsWith("data:image")) {
        const byteCharacters = atob(mainImage.split(",")[1]);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset++) {
          byteArrays.push(byteCharacters.charCodeAt(offset));
        }

        const blob = new Blob([new Uint8Array(byteArrays)], { type: "image/jpeg" });
        const file = new File([blob], "image.jpg", { type: "image/jpeg" });

        formData.append("mainlmg", file);
      }

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/V2/blog/all-blog/${id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          title: "Success!",
          text: "Blog Edited successfully!",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/dashboard/blogs/blogs-page");
        });
      }
    } catch (error) {
      console.error("Error editing blog:", error.response?.data || error.message);
      Swal.fire({
        title: "Error!",
        text: `Error editing blog: ${error.response?.data?.message || error.message}`,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Edit Blog
              </MDTypography>
              <MDBox mt={1} mb={2}>
                <MDTypography variant="body2" color="text">
                  Modify the details of your blog below.
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton variant="gradient" color="info" onClick={handleSave}>
                  Save
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <Card>
              <MDBox p={3} textAlign="center">
                {mainImage && (
                  <MDBox
                    component="img"
                    src={mainImage}
                    alt="Main Blog Image"
                    borderRadius="lg"
                    shadow="sm"
                    width="100%"
                    height="100%"
                    mb={2}
                  />
                )}
                <div {...getRootProps()} style={{ border: "1px dashed #ccc", padding: "10px" }}>
                  <input {...getInputProps()} />
                  <MDButton variant="gradient" color="info" component="span">
                    Upload Image
                  </MDButton>
                </div>
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5">Blog Information</MDTypography>
                <MDBox mt={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormField
                        type="text"
                        label="Title"
                        value={blog?.title || ""}
                        onChange={(e) => setBlog({ ...blog, title: e.target.value })}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormField
                        type="text"
                        label="Author"
                        value={blog?.author || ""}
                        onChange={(e) => setBlog({ ...blog, author: e.target.value })}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <MDBox mb={3} sm={6}>
                        <MDTypography
                          component="label"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          Category
                        </MDTypography>
                        <Select
                          value={selectedCategory}
                          onChange={setSelectedCategory}
                          options={categories}
                          placeholder="Select Category"
                          className="react-select-container"
                          classNamePrefix="react-select"
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6}>
                      <MDBox mb={3} sm={6}>
                        <MDTypography
                          component="label"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          Tag
                        </MDTypography>
                        <Select
                          isMulti
                          value={selectedTags}
                          onChange={(selectedOptions) => setSelectedTags(selectedOptions)}
                          options={tags}
                          placeholder="Select Tags"
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={12}>
                      <ReactQuill value={editorValue} onChange={setEditorValue} />
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditBlog;

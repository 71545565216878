import { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";

function LangagesPage() {
  const navigate = useNavigate();
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchLanguages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/languages`
      );
      console.log("API Response:", response.data);
      setLanguages(response.data.data || []);
      setLoading(false);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Failed to fetch languages from the server.",
        icon: "error",
        confirmButtonText: "OK",
      });
      setLoading(false);
      console.error("Error fetching languages:", error);
    }
  };

  useEffect(() => {
    fetchLanguages();
  }, []);

  const handleClick = (languageId) => {
    navigate(`/dashboard/18-langages/edit-langage/${languageId}`);
  };

  const deleteLanguage = async (id) => {
    try {
      // Show SweetAlert2 confirmation dialog
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This Language will be permanently deleted!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        // Proceed with the deletion
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/languages/${id}`);

        // Update the local state to remove the deleted
        setLanguages((prev) => prev.filter((language) => language._id !== id));

        // Show success alert
        Swal.fire("Deleted!", "The Language has been deleted.", "success");
      }
    } catch (error) {
      console.error("There was an error deleting the Language!", error);

      // Show error alert
      Swal.fire("Error!", "Failed to delete the Language. Please try again.", "error");
    }
  };

  const languagesTableData = {
    columns: [
      { Header: "Language Name", accessor: "name", align: "left" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],
    rows: Array.isArray(languages)
      ? languages.map((language) => ({
          name: language.name,
          actions: (
            <MDBox display="flex" justifyContent="center" gap={1}>
              <Tooltip title="Edit Language">
                <IconButton
                  color="info"
                  variant="contained"
                  onClick={() => handleClick(language._id)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Language">
                <IconButton color="error" onClick={() => deleteLanguage(language._id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </MDBox>
          ),
        }))
      : [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          All Languages
        </MDTypography>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={languagesTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LangagesPage;

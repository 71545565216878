import { useState, useEffect } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";

function SolutionsPage() {
  const [solutions, setSolutions] = useState([]);
  const navigate = useNavigate();

  // Filter Input :
  const [filteredSolutions, setFilteredSolutions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/V2/solution/all`)
      .then((response) => {
        console.log("Response data:", response.data);

        if (response.data.success && Array.isArray(response.data.data)) {
          setSolutions(response.data.data);
          setFilteredSolutions(response.data.data);
        } else {
          setSolutions([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching solutions:", error);
      });
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query === "") {
      setFilteredSolutions(solutions);
    } else {
      const filtered = solutions.filter((solution) =>
        solution.categories.some((category) =>
          category.name.toLowerCase().includes(query.toLowerCase())
        )
      );
      setFilteredSolutions(filtered);
    }
  };
  const deleteSolution = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This solution will be permanently deleted!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/V2/solution/${id}`);
        setSolutions((prev) => prev.filter((solution) => solution._id !== id));
        Swal.fire("Deleted!", "The solution has been deleted.", "success");
      }
    } catch (error) {
      console.error("Error deleting solution:", error);
      Swal.fire("Error!", "Failed to delete the solution. Please try again.", "error");
    }
  };

  const editSolution = (id) => {
    navigate(`/dashboard/9-solutions/edit-solution/${id}`);
  };

  const solutionsTableData = {
    columns: [
      { Header: "Title", accessor: "title", align: "left" },
      { Header: "Description", accessor: "description", align: "left" },
      { Header: "Categories", accessor: "categories", align: "left" },
      { Header: "Why Choose Us", accessor: "whyChoosUs", align: "left" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],
    rows:
      filteredSolutions.length > 0
        ? solutions.map((solution) => ({
            title: solution.title,
            description: solution.description,
            categories:
              solution.categories && solution.categories.length > 0
                ? solution.categories.map((cat) => cat.name).join(", ")
                : "No Categories",
            whyChoosUs:
              solution.whyChoosUs && solution.whyChoosUs.length > 0
                ? solution.whyChoosUs.join(", ")
                : "N/A",
            actions: (
              <MDBox display="flex" justifyContent="center" gap={1}>
                <Tooltip title="Edit Solution">
                  <IconButton color="info" onClick={() => editSolution(solution._id)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Solution">
                  <IconButton color="error" onClick={() => deleteSolution(solution._id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </MDBox>
            ),
          }))
        : [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          All Solutions
        </MDTypography>
      </MDBox>
      <MDBox mt={2} mb={2} mx={2}>
        {" "}
        <TextField
          value={searchQuery}
          onChange={handleSearch}
          label="Search by Categories"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: "1rem" }}
        />
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={solutionsTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SolutionsPage;

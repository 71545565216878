import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TextField from "@mui/material/TextField";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import Swal from "sweetalert2";

function CourseStatus({ isActive }) {
  return (
    <MDBadge
      variant="contained"
      color={isActive ? "success" : "error"}
      badgeContent={isActive ? "Active" : "Inactive"}
    />
  );
}

CourseStatus.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

function CourseSlider({ inSlider }) {
  return (
    <MDBadge
      variant="contained"
      color={inSlider ? "success" : "error"}
      badgeContent={inSlider ? "Yes" : "No"}
    />
  );
}

CourseSlider.propTypes = {
  inSlider: PropTypes.bool.isRequired,
};

function CoursesPage() {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);

  // Filter Input :
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/V2/course/all-course`)
      .then((response) => {
        const fetchedCourses = response.data.data;
        setCourses(fetchedCourses);
        setFilteredCourses(fetchedCourses);
      })
      .catch((error) => {
        console.error("There was an error fetching the courses data!", error);
      });
  }, []);

  // Filter Input :
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query === "") {
      setFilteredCourses(courses);
    } else {
      const filtered = courses.filter((course) =>
        course.categories.some((category) =>
          category.name.toLowerCase().includes(query.toLowerCase())
        )
      );
      setFilteredCourses(filtered);
    }
  };

  const handleClick = (courseId) => {
    navigate(`/dashboard/3-courses/edit-course/${courseId}`);
  };

  const deleteOneCourse = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This course will be permanently deleted!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/V2/course/all-course/${id}`);

        setCourses((prev) => prev.filter((course) => course._id !== id));
        setFilteredCourses((prev) => prev.filter((course) => course._id !== id));

        Swal.fire("Deleted!", "The course has been deleted.", "success");
      }
    } catch (error) {
      console.error("There was an error deleting the course!", error);

      Swal.fire("Error!", "Failed to delete the course. Please try again.", "error");
    }
  };

  const coursesTableData = {
    columns: [
      { Header: "Title", accessor: "title", align: "left" },
      { Header: "Categories", accessor: "categories", align: "left" },
      { Header: "Technologies", accessor: "technologies", align: "left" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "InSlider", accessor: "inslider", align: "center" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],
    rows: filteredCourses.map((course) => ({
      title: course.title,
      categories: course.categories.map((cat) => cat.name).join(", "),
      technologies: course.technologies.map((tech) => tech.name).join(", "),
      status: <CourseStatus isActive={course.isActive} />,
      inslider: <CourseSlider inSlider={course.inSlider} />,
      actions: (
        <MDBox display="flex" justifyContent="center" gap={1}>
          <Tooltip title="Update Course">
            <IconButton color="info" variant="contained" onClick={() => handleClick(course._id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Course">
            <IconButton color="error" onClick={() => deleteOneCourse(course._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          All Courses
        </MDTypography>
      </MDBox>

      <MDBox mt={2} mb={2} mx={2}>
        {" "}
        <TextField
          value={searchQuery}
          onChange={handleSearch}
          label="Search by Categories"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: "1rem" }}
        />
      </MDBox>

      <MDBox pt={3} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={coursesTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CoursesPage;

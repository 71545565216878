import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import Swal from "sweetalert2";

function PreferenceBadge({ preffer }) {
  const colorMap = {
    WhatsApp: "success",
    call: "info",
    mail: "warning",
  };

  return (
    <MDBadge variant="contained" color={colorMap[preffer] || "secondary"} badgeContent={preffer} />
  );
}

PreferenceBadge.propTypes = {
  preffer: PropTypes.oneOf(["WhatsApp", "call", "mail"]).isRequired,
};

function WorkWithUsPage() {
  const navigate = useNavigate();
  const [applicants, setApplicants] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/V2/workUsWith/forms`)
      .then((response) => {
        const fetchedData = response.data.data;
        setApplicants(fetchedData);
      })
      .catch((error) => {
        console.error("Error fetching applicants data!", error);
      });
  }, []);

  const handleEditClick = (id) => {
    navigate(`/dashboard/work-with-us/edit/${id}`);
  };

  const deleteApplicant = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This applicant will be permanently deleted!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/V2/workUsWith/forms/${id}`);

        setApplicants((prev) => prev.filter((applicant) => applicant._id !== id));

        Swal.fire("Deleted!", "The applicant has been deleted.", "success");
      }
    } catch (error) {
      console.error("Error deleting the applicant!", error);
      Swal.fire("Error!", "Failed to delete the applicant. Please try again.", "error");
    }
  };

  const applicantsTableData = {
    columns: [
      { Header: "Full Name", accessor: "fullName", align: "left" },
      { Header: "Type", accessor: "type", align: "left" },
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Phone", accessor: "phone", align: "left" },
      { Header: "Preferred Contact", accessor: "preffer", align: "center" },
      { Header: "Specialization", accessor: "specialization", align: "left" },
      { Header: "Experience (Years)", accessor: "experience", align: "center" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],
    rows: applicants.map((applicant) => ({
      fullName: applicant.fullName || "N/A",
      type: applicant.type || "N/A",
      email: applicant.email || "N/A",
      phone: applicant.phone || "N/A",
      preffer: <PreferenceBadge preffer={applicant.preffer || "N/A"} />,
      specialization: applicant.specialization?.join(", ") || "N/A",
      experience: applicant.experience || "N/A",

      actions: (
        <MDBox display="flex" justifyContent="center" gap={1}>
          {/* <Tooltip title="Edit">
            <IconButton color="info" onClick={() => handleEditClick(applicant._id)}>
              <EditIcon />
            </IconButton>
          </Tooltip> */}
          <Tooltip title="Delete">
            <IconButton color="error" onClick={() => deleteApplicant(applicant._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          Work With Us Applications
        </MDTypography>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={applicantsTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default WorkWithUsPage;

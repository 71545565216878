import { useState, useEffect } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";

function PortfolioPage() {
  const [portfolios, setPortfolios] = useState([]);

  // Filter Input :
  const [filteredPortfolios, setFilteredPortfolios] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  // Fetch portfolios data from API
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/V2/portfolio/all-portfolios`)
      .then((response) => {
        const fetchedPortfolios = response.data.data;
        setPortfolios(fetchedPortfolios);
        setFilteredPortfolios(fetchedPortfolios);
      })
      .catch((error) => {
        console.error("Error fetching portfolios:", error);
      });
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query === "") {
      setFilteredPortfolios(portfolios);
    } else {
      const filtered = portfolios.filter((portfolio) =>
        portfolio.categories.some((category) =>
          category.name.toLowerCase().includes(query.toLowerCase())
        )
      );
      setFilteredPortfolios(filtered);
    }
  };

  // Delete a portfolio
  const deletePortfolio = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/V2/portfolio/all-portfolios/${id}`
        );
        setPortfolios((prev) => prev.filter((portfolio) => portfolio._id !== id));

        Swal.fire("Deleted!", "The portfolio has been deleted.", "success");
      }
    } catch (error) {
      console.error("Error deleting portfolio:", error);
      Swal.fire("Error!", "Failed to delete the portfolio. Please try again.", "error");
    }
  };

  // Table data for portfolios
  const portfolioTableData = {
    columns: [
      { Header: "Title", accessor: "title", align: "left" },
      { Header: "Description", accessor: "mainDescription", align: "left" },
      { Header: "Categories", accessor: "categories", align: "left" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],
    rows: filteredPortfolios.map((portfolio) => ({
      title: portfolio.title,
      mainDescription: portfolio.mainDescription,
      categories:
        portfolio.categories.length > 0
          ? portfolio.categories.map((cat) => cat.name).join(", ")
          : "No Categories",
      actions: (
        <MDBox display="flex" justifyContent="center" gap={1}>
          <Tooltip title="Delete Portfolio">
            <IconButton color="error" onClick={() => deletePortfolio(portfolio._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          All Portfolios
        </MDTypography>
      </MDBox>
      <MDBox mt={2} mb={2} mx={2}>
        {" "}
        <TextField
          value={searchQuery}
          onChange={handleSearch}
          label="Search by Categories"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: "1rem" }}
        />
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={portfolioTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PortfolioPage;

import { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";

function CategoriesPage() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/V2/category/categories`
      );
      console.log("API Response:", response.data);
      setCategories(response.data.data || []);
      setLoading(false);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Failed to fetch categories from the server.",
        icon: "error",
        confirmButtonText: "OK",
      });
      setLoading(false);
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleClick = (categoryId) => {
    navigate(`/dashboard/6-categories/edit-category/${categoryId}`);
  };

  const deleteCategory = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This Category will be permanently deleted!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/V2/category/categories/${id}`);
        setCategories((prev) => prev.filter((category) => category._id !== id));

        Swal.fire("Deleted!", "The Category has been deleted.", "success");
      }
    } catch (error) {
      console.error("Error deleting category:", error);

      Swal.fire("Error!", "Failed to delete the Category. Please try again.", "error");
    }
  };

  const categoriesTableData = {
    columns: [
      { Header: "name", accessor: "name", align: "left" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],
    rows: Array.isArray(categories)
      ? categories.map((category) => ({
          name: category.name,

          actions: (
            <MDBox display="flex" justifyContent="center" gap={1}>
              <Tooltip name="Edit Category">
                <IconButton
                  color="info"
                  variant="contained"
                  onClick={() => handleClick(category._id)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip name="Delete Category">
                <IconButton color="error" onClick={() => deleteCategory(category._id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </MDBox>
          ),
        }))
      : [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          All Categories
        </MDTypography>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={categoriesTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CategoriesPage;

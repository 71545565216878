import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function EditHomeSlider() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [sliderData, setSliderData] = useState({
    title: "",
    mainDescription: "",
    shortDescription: "",
    buttonText: "",
    buttonLink: "",
    backgroundImg: null,
  });

  const [currentImg, setCurrentImg] = useState(null);

  const updateSliderData = (field, value) => {
    setSliderData((prev) => ({ ...prev, [field]: value }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSliderData((prevState) => ({
        ...prevState,
        backgroundImg: file,
      }));
    }
  };

  useEffect(() => {
    const fetchSliderData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/V2/homeSlider/all-sliders/${id}`
        );

        console.log("Response data:", response.data);

        const data = response.data;

        setSliderData({
          title: data.title || "",
          mainDescription: data.mainDescription || "",
          shortDescription: data.shortDescription || "",
          buttonText: data.buttonTxt || "",
          buttonLink: data.buttonLink || "",
          backgroundImg: null,
        });

        setCurrentImg(data.mainlmg?.url || "");
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Failed to fetch slider data.",
          icon: "error",
          confirmButtonText: "OK",
        });

        console.error("Error fetching slider data:", error);
      }
    };

    fetchSliderData();
  }, [id]);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("title", sliderData.title);
    formData.append("mainDescription", sliderData.mainDescription);
    formData.append("shortDescription", sliderData.shortDescription);
    formData.append("buttonTxt", sliderData.buttonText);
    formData.append("buttonLink", sliderData.buttonLink);
    if (sliderData.backgroundImg) {
      formData.append("mainlmg", sliderData.backgroundImg);
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/V2/homeSlider/all-sliders/${id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      Swal.fire({
        title: "Success!",
        text: "The home slider has been updated successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      navigate("/dashboard/4-homeSlider/homeSliders-page");
      console.log("Response:", response.data);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to update the home slider.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Edit Home Slider
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          Update the information about this Home Slider.
        </MDTypography>
      </MDBox>
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5" fontWeight="medium">
                  Home Slider Details
                </MDTypography>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="Title"
                      value={sliderData.title}
                      onChange={(e) => updateSliderData("title", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="Main Description"
                      value={sliderData.mainDescription}
                      onChange={(e) => updateSliderData("mainDescription", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="Short Description"
                      value={sliderData.shortDescription}
                      onChange={(e) => updateSliderData("shortDescription", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="Button Text"
                      value={sliderData.buttonText}
                      onChange={(e) => updateSliderData("buttonText", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="Button Link"
                      value={sliderData.buttonLink}
                      onChange={(e) => updateSliderData("buttonLink", e.target.value)}
                    />
                  </Grid>
                </Grid>
                <MDBox mt={4}>
                  <MDTypography variant="subtitle2" fontWeight="light" color="textSecondary" mb={1}>
                    Background Image
                  </MDTypography>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    border="2px dashed"
                    borderColor="grey.400"
                    bgcolor="grey.100"
                    borderRadius="8px"
                    py={4}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      id="file-input"
                    />
                    <MDButton
                      variant="contained"
                      color="primary"
                      size="medium"
                      onClick={() => document.getElementById("file-input").click()}
                    >
                      Browse Files
                    </MDButton>
                    {currentImg && !sliderData.backgroundImg && (
                      <img
                        src={currentImg}
                        alt="Current Slider Background"
                        style={{
                          width: "100%",
                          maxWidth: "150px",
                          marginTop: "10px",
                          borderRadius: "8px",
                        }}
                      />
                    )}
                    {sliderData.backgroundImg && (
                      <img
                        src={URL.createObjectURL(sliderData.backgroundImg)}
                        alt="Updated Slider Background"
                        style={{
                          width: "100%",
                          maxWidth: "150px",
                          marginTop: "10px",
                          borderRadius: "8px",
                        }}
                      />
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
            <MDBox mt={3} display="flex" justifyContent="flex-end">
              <MDButton variant="contained" color="primary" onClick={handleSubmit}>
                Save Changes
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditHomeSlider;

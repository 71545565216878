import { useState } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";

function CustomCampaignPage() {
  const [CustomCampaigns, setCustomCampaigns] = useState([
    {
      id: 1,
      tech: "React Basics",
      selectedExamTech: "Technology",
      course: "React",
      courseBatch: "lorem1",
      support: "lorem1",
      coupon: "lorem1",
    },
    {
      id: 2,
      tech: "React Basics",
      selectedExamTech: "Technology",
      course: "React",
      courseBatch: "lorem2",
      support: "lorem2",
      coupon: "lorem2",
    },
  ]);

  const addCustomCampaign = (newCustomCampaign) =>
    setCustomCampaigns((prev) => [...prev, { id: Date.now(), ...newCustomCampaign }]);

  const CustomCampaignsTableData = {
    columns: [
      { Header: "Technologie", accessor: "tech", align: "left" },
      { Header: "Selected Exam Technologie", accessor: "selectedExamTech", align: "left" },
      { Header: "Course", accessor: "course", align: "left" },
      { Header: "Course Batch", accessor: "courseBatch", align: "center" },
      { Header: "Support", accessor: "support", align: "center" },
      { Header: "Coupon", accessor: "coupon", align: "center" },
    ],
    rows: CustomCampaigns.map((CustomCampaign) => ({
      tech: CustomCampaign.tech,
      selectedExamTech: CustomCampaign.selectedExamTech,
      course: CustomCampaign.course,
      courseBatch: CustomCampaign.courseBatch,
      support: CustomCampaign.support,
      coupon: CustomCampaign.coupon,
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          All CustomCampaigns
        </MDTypography>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={CustomCampaignsTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CustomCampaignPage;

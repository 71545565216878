import { useState, useEffect } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";

function ServicesPage() {
  const [services, setServices] = useState([]);

  // Filter Input :
  const [filteredServices, setFilteredServices] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/V2/service/all-services`)
      .then((response) => {
        const fetchedServices = response.data;
        setServices(fetchedServices);
        setFilteredServices(fetchedServices);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query === "") {
      setFilteredServices(services);
    } else {
      const filtered = services.filter((service) =>
        service.categories.some((category) =>
          category.name.toLowerCase().includes(query.toLowerCase())
        )
      );
      setFilteredServices(filtered);
    }
  };
  const deleteService = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This service will be permanently deleted!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/V2/service/service/${id}`);
        setServices((prev) => prev.filter((service) => service._id !== id));
        Swal.fire("Deleted!", "The service has been deleted.", "success");
      }
    } catch (error) {
      console.error("Error deleting service:", error);
      Swal.fire("Error!", "Failed to delete the service. Please try again.", "error");
    }
  };

  const servicesTableData = {
    columns: [
      { Header: "Title", accessor: "title", align: "left" },
      { Header: "Description", accessor: "description", align: "left" },
      { Header: "Categories", accessor: "categories", align: "left" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],
    rows: filteredServices.map((service) => ({
      title: service.title,
      description: service.description,
      categories:
        service.categories && service.categories.length > 0
          ? service.categories.map((cat) => cat.name).join(", ")
          : "No Categories",
      actions: (
        <MDBox display="flex" justifyContent="center" gap={1}>
          <Tooltip title="Delete Service">
            <IconButton color="error" onClick={() => deleteService(service._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          All Services
        </MDTypography>
      </MDBox>
      <MDBox mt={2} mb={2} mx={2}>
        {" "}
        <TextField
          value={searchQuery}
          onChange={handleSearch}
          label="Search by Categories"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: "1rem" }}
        />
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={servicesTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ServicesPage;

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import ReactQuill from "react-quill";
import { useDropzone } from "react-dropzone";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Select from "react-select";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function AddNewPromotion() {
  const [promotionData, setPromotionData] = useState({
    userId: "",
    courseID: "",
    email: "",
    fullName: "",
    phone: "",
    status: "pending",
    preffer: "",
    chooseTech: [],
    modules: [],
    type: "",
    numberOfStudents: "",
    certificationNeeded: [],
  });

  const [technologies, setTechnologies] = useState([]);
  const [modules, setModules] = useState([]);
  const [certifications, setCertifications] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const techResponse = await axios.get(
          "${process.env.REACT_APP_API_URL}/api/v2/technology/technologies"
        );
        setTechnologies(techResponse.data.data || []);

        const moduleResponse = await axios.get(
          "${process.env.REACT_APP_API_URL}/api/v2/module/modules"
        );
        setModules(moduleResponse.data.data || []);

        const certResponse = await axios.get(
          "${process.env.REACT_APP_API_URL}/api/v2/certification/certifications"
        );
        setCertifications(certResponse.data.data || []);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);

  const updatePromotionData = (field, value) => {
    setPromotionData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        "${process.env.REACT_APP_API_URL}/api/v2/promotion/add-promotion",
        promotionData
      );

      if (response.status === 201) {
        Swal.fire({
          title: "Success!",
          text: "Promotion added successfully!",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/dashboard/promotions");
        });
      }
    } catch (error) {
      console.error("Error adding promotion:", error.response?.data || error.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Add New Promotion
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          This information will describe more about the promotion.
        </MDTypography>
      </MDBox>

      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  label="Full Name"
                  value={promotionData.fullName}
                  onChange={(e) => updatePromotionData("fullName", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormField
                  type="email"
                  label="Email"
                  value={promotionData.email}
                  onChange={(e) => updatePromotionData("email", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  label="Phone"
                  value={promotionData.phone}
                  onChange={(e) => updatePromotionData("phone", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  label="Number of Students"
                  value={promotionData.numberOfStudents}
                  onChange={(e) => updatePromotionData("numberOfStudents", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Select
                  options={[
                    { label: "Pending", value: "pending" },
                    { label: "Accepted", value: "accepted" },
                    { label: "Rejected", value: "rejected" },
                  ]}
                  value={{ label: promotionData.status, value: promotionData.status }}
                  onChange={(selectedOption) => updatePromotionData("status", selectedOption.value)}
                  placeholder="Select Status"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Select
                  options={[
                    { label: "WhatsApp", value: "whatsapp" },
                    { label: "Call", value: "call" },
                    { label: "Mail", value: "mail" },
                  ]}
                  value={{ label: promotionData.preffer, value: promotionData.preffer }}
                  onChange={(selectedOption) =>
                    updatePromotionData("preffer", selectedOption.value)
                  }
                  placeholder="Preferred Contact Method"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                {Array.isArray(technologies) && (
                  <Select
                    options={technologies.map((tech) => ({ label: tech.name, value: tech._id }))}
                    value={promotionData.chooseTech.map((tech) => ({
                      label: technologies.find((t) => t._id === tech)?.name,
                      value: tech,
                    }))}
                    onChange={(selectedOptions) =>
                      updatePromotionData(
                        "chooseTech",
                        selectedOptions ? selectedOptions.map((opt) => opt.value) : []
                      )
                    }
                    isMulti
                    placeholder="Select Technologies"
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                {Array.isArray(modules) && (
                  <Select
                    options={modules.map((mod) => ({ label: mod.title, value: mod._id }))}
                    value={promotionData.modules.map((mod) => ({
                      label: modules.find((m) => m._id === mod)?.title,
                      value: mod,
                    }))}
                    onChange={(selectedOptions) =>
                      updatePromotionData(
                        "modules",
                        selectedOptions ? selectedOptions.map((opt) => opt.value) : []
                      )
                    }
                    isMulti
                    placeholder="Select Modules"
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                <Select
                  options={[
                    { label: "Remote", value: "remote" },
                    { label: "On Site", value: "onSite" },
                  ]}
                  value={{ label: promotionData.type, value: promotionData.type }}
                  onChange={(selectedOption) => updatePromotionData("type", selectedOption.value)}
                  placeholder="Select Type"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                {Array.isArray(certifications) && (
                  <Select
                    options={certifications.map((cert) => ({ label: cert.name, value: cert._id }))}
                    value={promotionData.certificationNeeded.map((cert) => ({
                      label: certifications.find((c) => c._id === cert)?.name,
                      value: cert,
                    }))}
                    onChange={(selectedOptions) =>
                      updatePromotionData(
                        "certificationNeeded",
                        selectedOptions ? selectedOptions.map((opt) => opt.value) : []
                      )
                    }
                    isMulti
                    placeholder="Select Certifications"
                  />
                )}
              </Grid>
            </Grid>

            <MDBox mt={4} display="flex" justifyContent="flex-end">
              <MDButton variant="contained" color="primary" onClick={handleSubmit}>
                Submit Promotion
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddNewPromotion;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function EditTestimonial() {
  const { id } = useParams();
  console.log("Testimonial ID:", id);
  const navigate = useNavigate();
  const [testimonialData, setTestimonialData] = useState({
    fullName: "",
    typeOf: "",
    description: "",
    occupation: "",
    rating: "",
  });
  const [loading, setLoading] = useState(true);

  const updateTestimonialData = (field, value) => {
    setTestimonialData((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    const fetchTestimonial = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/V2/testmon/testimonial/${id}`
        );
        setTestimonialData(response.data);
        setLoading(false);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Failed to fetch testimonial data.",
          icon: "error",
          confirmButtonText: "OK",
        });
        setLoading(false);
      }
    };
    fetchTestimonial();
  }, [id]);

  const handleSubmit = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/V2/testmon/testimonial/${id}`,
        testimonialData
      );

      Swal.fire({
        title: "Success!",
        text: "The testimonial has been updated successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      navigate("/dashboard/12-Testimonials/Testimonials-page");
      console.log("Response:", response.data);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to update the testimonial.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error:", error);
    }
  };

  if (loading) {
    return (
      <MDBox mt={6} textAlign="center">
        <MDTypography variant="h4" color="info">
          Loading...
        </MDTypography>
      </MDBox>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Edit Testimonial
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          Update the details of the testimonial below.
        </MDTypography>
      </MDBox>
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5" fontWeight="medium">
                  Testimonial Details
                </MDTypography>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="Full Name"
                      value={testimonialData.fullName}
                      onChange={(e) => updateTestimonialData("fullName", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="Occupation"
                      value={testimonialData.occupation}
                      onChange={(e) => updateTestimonialData("occupation", e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Type Of
                    </MDTypography>
                    <Select
                      options={[
                        { label: "individual", value: "individual" },
                        { label: "group", value: "group" },
                      ]}
                      value={{ label: testimonialData.typeOf, value: testimonialData.typeOf }}
                      onChange={(selectedOption) =>
                        updateTestimonialData("typeOf", selectedOption.value)
                      }
                      placeholder="Type Of"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Rating
                    </MDTypography>
                    <Select
                      options={[
                        { label: "1", value: "1" },
                        { label: "2", value: "2" },
                        { label: "3", value: "3" },
                        { label: "4", value: "4" },
                        { label: "5", value: "5" },
                      ]}
                      value={{ label: testimonialData.rating, value: testimonialData.rating }}
                      onChange={(selectedOption) =>
                        updateTestimonialData("rating", selectedOption.value)
                      }
                      placeholder="Rating"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      type="text"
                      label="Description"
                      value={testimonialData.description}
                      onChange={(e) => updateTestimonialData("description", e.target.value)}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
            <MDBox mt={3} display="flex" justifyContent="flex-end">
              <MDButton variant="contained" color="primary" onClick={handleSubmit}>
                Save Changes
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditTestimonial;

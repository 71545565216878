import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import axios from "axios";
import Swal from "sweetalert2";

function AddNewNews() {
  const navigate = useNavigate();

  // State for the form inputs
  const [formData, setFormData] = useState({
    title: "",
    author: "",
    date: "",
    category: "",
    summary: "",
    content: "",
    image: "",
    isFeatured: false,
  });

  const categories = ["Technology", "Sports", "Politics", "Health", "Entertainment"];

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Submit form data to the API
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Validation
      if (!formData.title || !formData.author || !formData.date || !formData.category) {
        Swal.fire("Error!", "Please fill in all required fields.", "error");
        return;
      }

      // Send POST request
      await axios.post("${process.env.REACT_APP_API_URL}/api/news/add", formData);

      Swal.fire("Success!", "News article has been added.", "success");
      navigate("/dashboard/news"); // Redirect to the news list page
    } catch (error) {
      console.error("Error adding news article:", error);
      Swal.fire("Error!", "Failed to add the news article.", "error");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5" mb={2}>
                  Add New News Article
                </MDTypography>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    {/* Title */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Title"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        required
                      />
                    </Grid>

                    {/* Author */}
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Author"
                        name="author"
                        value={formData.author}
                        onChange={handleChange}
                        required
                      />
                    </Grid>

                    {/* Date */}
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        type="date"
                        label="Date"
                        name="date"
                        InputLabelProps={{ shrink: true }}
                        value={formData.date}
                        onChange={handleChange}
                        required
                      />
                    </Grid>

                    {/* Category */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        select
                        label="Category"
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        required
                      >
                        {categories.map((category) => (
                          <MenuItem key={category} value={category}>
                            {category}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Summary */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Summary"
                        name="summary"
                        value={formData.summary}
                        onChange={handleChange}
                        multiline
                        rows={2}
                        required
                      />
                    </Grid>

                    {/* Content */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Content"
                        name="content"
                        value={formData.content}
                        onChange={handleChange}
                        multiline
                        rows={4}
                        required
                      />
                    </Grid>

                    {/* Image URL */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Image URL"
                        name="image"
                        value={formData.image}
                        onChange={handleChange}
                      />
                    </Grid>

                    {/* Featured */}
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="isFeatured"
                            checked={formData.isFeatured}
                            onChange={handleChange}
                          />
                        }
                        label="Feature this news article"
                      />
                    </Grid>

                    {/* Submit Button */}
                    <Grid item xs={12}>
                      <Button type="submit" variant="contained" color="info" fullWidth>
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddNewNews;

import { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Switch from "@mui/material/Switch";
import { useDropzone } from "react-dropzone";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function AddNewElectronicSupport() {
  const [supportData, setSupportData] = useState({
    cv: null,
    fileUrl: "",
    isForCourse: false,
    isForExam: false,
    description: "",
    title :""
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setSupportData((prevData) => ({
          ...prevData,
          cv: file,
        }));
      }
    },
    accept: "*",
  });

  const updateSupportData = (field, value) => {
    setSupportData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
  
      if (supportData.cv) {
        formData.append("cv", supportData.cv);
      }
      formData.append("fileUrl", supportData.fileUrl);
      formData.append("isForCourse", supportData.isForCourse);
      formData.append("isForExam", supportData.isForExam);
      formData.append("description", supportData.description);
      formData.append("title", supportData.title);
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/V2/slectronicsupport/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.status === 201) {
        Swal.fire({
          title: "Success!",
          text: "Electronic support added successfully!",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/dashboard/14-electronic-support/electronicSupport-page");
        });
      }
    } catch (error) {
      console.error("Error adding electronic support:", error.response?.data || error.message);
      const errorMessage =
        error.response?.data?.message || "Failed to add electronic support. Please try again.";
      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Add New Electronic Support
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          This form allows you to add new electronic support materials.
        </MDTypography>
      </MDBox>

      <MDBox pt={6} pb={3}>
        <MDBox mt={4}>
          <Card>
            <MDBox p={3}>
              <MDTypography variant="h5" fontWeight="medium" gutterBottom>
                Electronic Support Details
              </MDTypography>
              <MDBox mt={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Upload File
                    </MDTypography>
                    <MDBox
                      {...getRootProps()}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      border="2px dashed"
                      borderRadius="8px"
                      borderColor="grey.400"
                      bgcolor="grey.100"
                      py={4}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "grey.200",
                        },
                        transition: "background-color 0.2s ease-in-out",
                      }}
                    >
                      <input {...getInputProps()} />
                      {!supportData.cv && (
                        <>
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="textSecondary"
                            mb={1}
                          >
                            Drag & drop a file here, or
                          </MDTypography>
                          <MDButton variant="contained" color="primary" size="medium">
                            Browse Files
                          </MDButton>
                        </>
                      )}
                    </MDBox>
                    {supportData.cv && (
                      <MDTypography variant="subtitle2" mt={2}>
                        File Uploaded: {supportData.cv.name}
                      </MDTypography>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <FormField
                      type="text"
                      label="File URL"
                      value={supportData.fileUrl}
                      onChange={(e) => updateSupportData("fileUrl", e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Is For Course
                    </MDTypography>
                    <Switch
                      checked={supportData.isForCourse}
                      onChange={(e) => updateSupportData("isForCourse", e.target.checked)}
                      color="primary"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Is For Exam
                    </MDTypography>
                    <Switch
                      checked={supportData.isForExam}
                      onChange={(e) => updateSupportData("isForExam", e.target.checked)}
                      color="primary"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormField
                      type="text"
                      label="Description"
                      value={supportData.description}
                      onChange={(e) => updateSupportData("description", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      type="text"
                      label="title"
                      value={supportData.name}
                      onChange={(e) => updateSupportData("title", e.target.value)}
                    />
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox mt={4} display="flex" justifyContent="flex-end">
                <MDButton variant="contained" color="primary" onClick={handleSubmit}>
                  Submit Support
                </MDButton>
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddNewElectronicSupport;

import { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Select from "react-select";
import { useDropzone } from "react-dropzone";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { TextField, Chip, Box, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function AddNewWorkWithUsForm() {
  const [formData, setFormData] = useState({
    fullName: "",
    type: "",
    email: "",
    phone: "",
    preffer: "",
    certifications: [],
    specialization: [],
    cv: null,
    techSkills: "",
    teatchSkills: "",
    experience: "",
    schoolLevel: "",
  });

  const navigate = useNavigate();

  const typeOptions = [
    { value: "student", label: "Student" },
    { value: "enterprise", label: "Enterprise" },
  ];

  const prefferOptions = [
    { value: "WhatsApp", label: "WhatsApp" },
    { value: "call", label: "Call" },
    { value: "mail", label: "Mail" },
  ];

  const enumOptions = [1, 2, 3, 4, 5].map((n) => ({ value: n, label: n }));
  const experienceOptions = Array.from({ length: 10 }, (_, i) => ({
    value: i + 1,
    label: i + 1,
  }));

  const schoolLevels = [
    { value: "highSchool", label: "HighSchool" },
    { value: "bachelor", label: "Bachelor" },
    { value: "master", label: "Master" },
    { value: "phD", label: "phD" },
  ];

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file && file.type === "application/pdf") {
        setFormData((prevData) => ({ ...prevData, cv: file }));
      } else {
        Swal.fire("Error", "Only PDF files are allowed.", "error");
      }
    },
    accept: "application/pdf",
  });

  const updateField = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleSubmit = async () => {
    try {
      if (!formData.fullName || !formData.email || !formData.phone || !formData.type) {
        Swal.fire("Error", "Please fill out all required fields.", "error");
        return;
      }

      const payload = new FormData();

      Object.entries(formData).forEach(([key, value]) => {
        if (key === "cv" && value) {
          payload.append(key, value);
        } else if (Array.isArray(value)) {
          payload.append(key, JSON.stringify(value));
        } else {
          payload.append(key, value);
        }
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/V2/workUsWith/apply`,
        payload,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.status === 201) {
        Swal.fire("Success", "Your submission was successful!", "success").then(() =>
          navigate("/dashboard/15-work-with-us/work-with-us-page")
        );
      }
    } catch (error) {
      console.error("Error submitting form:", error.message);
      Swal.fire("Error", "Submission failed. Please try again.", "error");
    }
  };
  // const handleKeyDown = (event) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault();
  //   }
  // };

  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default Enter behavior
    }
  };
  const handleKeyDown2 = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default Enter behavior
    }
  };
  const handleCertificationsChange = (event, newValue) => {
    setCertifications(newValue);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Add New Worker
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          This information will describe more about the Worker.
        </MDTypography>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3}>
            <MDTypography variant="h5" mb={3}>
              Add New Work With Us
            </MDTypography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <FormField
                  label="Full Name"
                  type="text"
                  value={formData.fullName}
                  onChange={(e) => updateField("fullName", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  label="Email"
                  type="email"
                  value={formData.email}
                  onChange={(e) => updateField("email", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  label="Phone"
                  type="text"
                  value={formData.phone}
                  onChange={(e) => updateField("phone", e.target.value)}
                />
              </Grid>
              {/* Certifications */}
              <Grid item xs={12} sm={4}>
                <Box mt={2}>
                  {formData.certifications?.map((certification, index) => (
                    <Chip
                      key={index}
                      label={certification}
                      onDelete={() =>
                        updateField(
                          "certifications",
                          formData.certifications.filter((o) => o !== certification)
                        )
                      }
                      color="primary"
                      sx={{ margin: 0.5 }}
                    />
                  ))}
                </Box>
                <Typography component="label" variant="button" fontWeight="regular" color="text">
                  Certifications
                </Typography>
                <TextField
                  fullWidth
                  label="Add Certifications"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && inputValue.trim() !== "") {
                      updateField("certifications", [
                        ...(formData.certifications || []),
                        inputValue.trim(),
                      ]);
                      setInputValue("");
                    }
                  }}
                  placeholder="Press Enter to add a certification"
                  variant="outlined"
                  onKeyPress={handleKeyDown}
                  multiline
                  rows={3} // Allows multiple lines for text area
                />
              </Grid>

              {/* Specializations */}
              <Grid item xs={12} sm={4}>
                <Box mt={2}>
                  {formData.specialization?.map((specialization, index) => (
                    <Chip
                      key={index}
                      label={specialization}
                      onDelete={() =>
                        updateField(
                          "specialization",
                          formData.specialization.filter((o) => o !== specialization)
                        )
                      }
                      color="primary"
                      sx={{ margin: 0.5 }}
                    />
                  ))}
                </Box>
                <Typography component="label" variant="button" fontWeight="regular" color="text">
                  Specializations
                </Typography>
                <TextField
                  fullWidth
                  label="Add specialization"
                  value={inputValue2}
                  onChange={(e) => setInputValue2(e.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && inputValue2.trim() !== "") {
                      updateField("specialization", [
                        ...(formData.specialization || []),
                        inputValue2.trim(),
                      ]);
                      setInputValue2("");
                    }
                  }}
                  placeholder="Press Enter to add a specialization"
                  variant="outlined"
                  onKeyPress={handleKeyDown}
                  multiline
                  rows={3} // Allows multiple lines for text area
                />
              </Grid>

              {/* working */}
              {/* Certifications */}
              {/* <Grid item xs={12} sm={4}>
                <Box mt={2}>
                  {formData.certifications?.map((certification, index) => (
                    <Chip
                      key={index}
                      label={certification}
                      onDelete={() =>
                        updateField(
                          "certifications",
                          formData.certifications.filter((o) => o !== certification)
                        )
                      }
                      color="primary"
                      sx={{ margin: 0.5 }}
                    />
                  ))}
                </Box>
                <Typography component="label" variant="button" fontWeight="regular" color="text">
                  Certifications
                </Typography>
                <TextField
                  fullWidth
                  label="Add Certifications"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && inputValue.trim() !== "") {
                      updateField("certifications", [
                        ...(formData.certifications || []),
                        inputValue.trim(),
                      ]);
                      setInputValue("");
                    }
                  }}
                  placeholder="Press Enter to add an certification"
                  variant="outlined"
                  onKeyPress={handleKeyDown}
                />
              </Grid> */}

              {/* Specializations */}
              {/* <Grid item xs={12} sm={4}>
                <Box mt={2}>
                  {formData.specialization?.map((specializations, index) => (
                    <Chip
                      key={index}
                      label={specializations}
                      onDelete={() =>
                        updateField(
                          "specialization",
                          formData.specialization.filter((o) => o !== specializations)
                        )
                      }
                      color="primary"
                      sx={{ margin: 0.5 }}
                    />
                  ))}
                </Box>
                <Typography component="label" variant="button" fontWeight="regular" color="text">
                  specialization
                </Typography>
                <TextField
                  fullWidth
                  label="Add specialization"
                  value={inputValue2}
                  onChange={(e) => setInputValue2(e.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && inputValue2.trim() !== "") {
                      updateField("specialization", [
                        ...(formData.specialization || []),
                        inputValue2.trim(),
                      ]);
                      setInputValue2("");
                    }
                  }}
                  placeholder="Press Enter to add an specialization"
                  variant="outlined"
                  onKeyPress={handleKeyDown}
                />
              </Grid> */}
              {/* working */}

              {/* <Grid item xs={12} sm={4}>
                <Typography component="label" variant="button" fontWeight="regular" color="text">
                  Certifications
                </Typography>
                <Autocomplete
                  multiple
                  freeSolo
                  options={[]}
                  value={formData.certifications || []}
                  onChange={(event, newValue) => {
                    updateField("certifications", newValue);
                  }}
                  renderTags={(value, getCertProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        label={option}
                        {...getCertProps({ index })}
                        color="primary"
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Add Certifications"
                      placeholder="Type and press Enter"
                    />
                  )}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={4}>
                <FormField
                  label="Certifications"
                  type="text"
                  placeholder="Enter certificationss (comma separated)"
                  value={formData.certifications}
                  onChange={(e) =>
                    updateField(
                      "certifications",
                      e.target.value.split(",").map((s) => s.trim())
                    )
                  }
                />
              </Grid> */}

              {/* <Grid item xs={12} sm={4}>
                <Typography component="label" variant="button" fontWeight="regular" color="text">
                  Specializations
                </Typography>
                <Autocomplete
                  multiple
                  freeSolo
                  options={[]}
                  value={formData.specialization || []}
                  onChange={(event, newValue) => {
                    updateField("specialization", newValue);
                  }}
                  renderTags={(value, getCertProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        label={option}
                        {...getCertProps({ index })}
                        color="primary"
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Add specialization"
                      placeholder="Type and press Enter"
                    />
                  )}
                />
              </Grid>   */}

              {/* <Grid item xs={12} sm={4}>
                <FormField
                  label="Specializations"
                  type="text"
                  placeholder="Enter specializations (comma separated)"
                  value={formData.specialization}
                  onChange={(e) =>
                    updateField(
                      "specialization",
                      e.target.value.split(",").map((s) => s.trim())
                    )
                  }
                />
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <MDTypography component="label" variant="button" color="text">
                  prefferred Contact Method
                </MDTypography>
                <Select
                  options={prefferOptions}
                  placeholder="Select prefference"
                  onChange={(selected) => updateField("preffer", selected.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDTypography component="label" variant="button" color="text">
                  Type
                </MDTypography>
                <Select
                  options={typeOptions}
                  placeholder="Select Type"
                  onChange={(selected) => updateField("type", selected.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDTypography component="label" variant="button" color="text">
                  Technical Skills
                </MDTypography>
                <Select
                  options={enumOptions}
                  placeholder="Select Level"
                  onChange={(selected) => updateField("techSkills", selected.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDTypography component="label" variant="button" color="text">
                  Teaching Skills
                </MDTypography>
                <Select
                  options={enumOptions}
                  placeholder="Select Level"
                  onChange={(selected) => updateField("teatchSkills", selected.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDTypography component="label" variant="button" color="text">
                  Experience (Years)
                </MDTypography>
                <Select
                  options={experienceOptions}
                  placeholder="Select Experience"
                  onChange={(selected) => updateField("experience", selected.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDTypography component="label" variant="button" color="text">
                  School Level
                </MDTypography>
                <Select
                  options={schoolLevels}
                  placeholder="Select Level"
                  onChange={(selected) => updateField("schoolLevel", selected.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography
                  component="label"
                  variant="button"
                  color="text"
                  fontWeight="bold"
                  mb={1}
                >
                  Upload CV (PDF)
                </MDTypography>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  border="2px dashed"
                  borderColor="grey.400"
                  bgcolor="grey.100"
                  borderRadius="8px"
                  py={3}
                  sx={{
                    cursor: "pointer",
                    transition: "background-color 0.3s ease-in-out",
                    "&:hover": { backgroundColor: "grey.200" },
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} style={{ display: "none" }} />
                  <MDTypography variant="button" color="textSecondary" mb={1}>
                    Drag & drop your CV here, or
                  </MDTypography>
                  <MDButton variant="contained" color="primary" size="medium">
                    Browse Files
                  </MDButton>
                </MDBox>
                {formData.cv && (
                  <MDTypography mt={2} variant="body2" color="textSecondary" textAlign="center">
                    Uploaded: {formData.cv.name}
                  </MDTypography>
                )}
              </Grid>

              {/* <Grid item xs={12} sm={4}>
                <MDTypography component="label" variant="button" color="text">
                  Upload CV (PDF)
                </MDTypography>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <MDButton>Upload CV</MDButton>
                </div>
                {formData.cv && <MDTypography mt={2}>Uploaded: {formData.cv.name}</MDTypography>}
              </Grid> */}
            </Grid>
            <MDBox mt={4} display="flex" justifyContent="flex-end">
              <MDButton variant="contained" color="primary" onClick={handleSubmit}>
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddNewWorkWithUsForm;

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import ReactQuill from "react-quill";
import { useDropzone } from "react-dropzone";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Select from "react-select";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { List, ListItem } from "@mui/material";
import { MenuItem, FormControl, InputLabel, TextField, Chip, Box, Typography } from "@mui/material";


function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function ExamDetails({ examData, updateExamData, categories, technologies, supports, copons }) {
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  return (
    <MDBox>
      <MDTypography variant="h5">Exam Details</MDTypography>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              type="text"
              label="Title"
              value={examData.title}
              onChange={(e) => updateExamData("title", e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <MDTypography component="label" variant="button" fontWeight="regular" color="text">
              Description
            </MDTypography>
            <ReactQuill
              value={examData.description}
              onChange={(value) => updateExamData("description", value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {Array.isArray(technologies) && (
              <Select
                options={technologies.map((item) => ({
                  label: item.name,
                  value: item._id,
                }))}
                value={
                  examData.technologies
                    ? technologies
                        .filter((tech) => examData.technologies.includes(tech._id))
                        .map((tech) => ({ label: tech.name, value: tech._id }))
                    : []
                }
                onChange={(selectedOptions) =>
                  updateExamData(
                    "technologies",
                    selectedOptions ? selectedOptions.map((opt) => opt.value) : []
                  )
                }
                isMulti
                placeholder="Select Technologies"
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {Array.isArray(categories) && (
              <Select
                options={categories.map((item) => ({
                  label: item.name,
                  value: item._id,
                }))}
                value={
                  examData.categories
                    ? categories
                        .filter((cat) => examData.categories.includes(cat._id))
                        .map((cat) => ({ label: cat.name, value: cat._id }))
                    : []
                }
                onChange={(selectedOptions) =>
                  updateExamData(
                    "categories",
                    selectedOptions ? selectedOptions.map((opt) => opt.value) : []
                  )
                }
                isMulti
                placeholder="Select Categories"
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {Array.isArray(supports) && (
              <Select
                options={supports.map((item) => ({
                  label: item.title,
                  value: item._id,
                }))}
                value={
                  examData.supports
                    ? supports
                        .filter((cat) => examData.supports.includes(cat._id))
                        .map((cat) => ({ label: cat.title, value: cat._id }))
                    : []
                }
                onChange={(selectedOptions) =>
                  updateExamData(
                    "supports",
                    selectedOptions ? selectedOptions.map((opt) => opt.value) : []
                  )
                }
                isMulti
                placeholder="Select Supports"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {Array.isArray(copons) && (
              <Select
                options={copons.map((item) => ({
                  label: item.code,
                  value: item._id,
                }))}
                value={
                  examData.copon
                    ? {
                        label: copons.find((copon) => copon._id === examData.copon)?.code,
                        value: examData.copon,
                      }
                    : null
                }
                onChange={(selectedOption) =>
                  updateExamData("copon", selectedOption ? selectedOption.value : null)
                }
                placeholder="Select copon"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDTypography component="label" variant="button" fontWeight="regular" color="text">
              On Site
            </MDTypography>
            <Select
              options={[{ label: "PersonVue", value: "personVue" }]}
              value={{ label: examData.onSite, value: examData.onSite }}
              onChange={(selectedOption) => updateExamData("onSite", selectedOption.value)}
              placeholder="Select On Site"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
              <Box mt={2}>
                <Typography component="label" variant="button" fontWeight="regular" color="text">
                  Prerequisites
                </Typography>
                <List>
                  {examData.prerequisites?.map((prerequisite, index) => (
                    <ListItem key={index} disablePadding>
                      <Chip
                        label={prerequisite}
                        onDelete={() =>
                          updateExamData(
                            "prerequisites",
                            examData.prerequisites.filter((prereq) => prereq !== prerequisite)
                          )
                        }
                        color="primary"
                        sx={{ margin: 0.8 }}
                      />
                    </ListItem>
                  ))}
                </List>
                <TextField
                  fullWidth
                  label="Add Prerequisites"
                  value={inputValue2}
                  onChange={(e) => setInputValue2(e.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && inputValue2.trim() !== "") {
                      updateExamData("prerequisites", [
                        ...(examData.prerequisites || []),
                        inputValue2.trim(),
                      ]);
                      setInputValue2("");
                    }
                  }}
                  placeholder="Press Enter to add a prerequisite"
                  variant="outlined"
                  multiline
                  rows={3}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box mt={2}>
                <Typography component="label" variant="button" fontWeight="regular" color="text">
                  Objectifs
                </Typography>
                <List>
                  {examData.objectifs?.map((objectif, index) => (
                    <ListItem key={index} disablePadding>
                      <Chip
                        label={objectif}
                        onDelete={() =>
                          updateExamData(
                            "objectifs",
                            examData.objectifs.filter((obj) => obj !== objectif)
                          )
                        }
                        color="primary"
                        sx={{ margin: 0.8 }}
                      />
                    </ListItem>
                  ))}
                </List>
                <TextField
                  fullWidth
                  label="Add Objectifs"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && inputValue.trim() !== "") {
                      updateExamData("objectifs", [
                        ...(examData.objectifs || []),
                        inputValue.trim(),
                      ]);
                      setInputValue("");
                    }
                  }}
                  placeholder="Press Enter to add an objectif"
                  variant="outlined"
                  multiline
                  rows={3}
                />
              </Box>
            </Grid>

          <Grid item xs={12} sm={6}>
            <MDTypography component="label" variant="button" fontWeight="regular" color="text">
              Remote
            </MDTypography>
            <Select
              options={[
                { label: "On Vue", value: "onVue" },
                { label: "Home", value: "home" },
              ]}
              value={{ label: examData.remote, value: examData.remote }}
              onChange={(selectedOption) => updateExamData("remote", selectedOption.value)}
              placeholder="Select Remote"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDTypography component="label" variant="button" fontWeight="regular" color="text">
              language
            </MDTypography>
            <Select
              options={[
                { label: "English", value: "English" },
                { label: "French", value: "French" },
                { label: "Arabic", value: "Arabic" },
                { label: "Spanish", value: "Spanish" },
                { label: "German", value: "German" },
              ]}
              value={{ label: examData.language, value: examData.language }}
              onChange={(selectedOption) => updateExamData("language", selectedOption.value)}
              placeholder="Select Language"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type="number"
              label="Price"
              value={examData.price}
              onChange={(e) => updateExamData("price", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type="number"
              label="Attempts Allowed"
              value={examData.attemptsAllowed}
              onChange={(e) => updateExamData("attemptsAllowed", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type="number"
              label="Reserved Places"
              value={examData.reservedPlaces}
              onChange={(e) => updateExamData("reservedPlaces", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type="number"
              label="Sum of Site"
              value={examData.sumOfSite}
              onChange={(e) => updateExamData("sumOfSite", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type="number"
              label="Duration"
              value={examData.duration}
              onChange={(e) => updateExamData("duration", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <MDTypography component="label" variant="button" fontWeight="regular" color="text">
              Active
            </MDTypography>
            <Switch
              checked={examData.isActive}
              onChange={(e) => updateExamData("isActive", e.target.checked)}
              color="primary"
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <MDTypography component="label" variant="button" fontWeight="regular" color="text">
              In Slider
            </MDTypography>
            <Switch
              checked={examData.inSlider}
              onChange={(e) => updateExamData("inSlider", e.target.checked)}
              color="primary"
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

function Media({ examData, updateExamData }) {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      updateExamData("mainlmg", acceptedFiles[0]);
    },
    accept: "image/*",
  });

  const handleImageClick = () => {
    document.getElementById("file-input").click();
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      updateExamData("mainlmg", e.target.files[0]);
    }
  };

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="medium" gutterBottom>
        Media
      </MDTypography>

      <MDBox mt={3}>
        <MDBox mb={1} ml={0.5}>
          <MDTypography
            component="label"
            variant="subtitle1"
            fontWeight="light"
            color="textSecondary"
          >
            Main Image
          </MDTypography>
        </MDBox>

        {!examData.mainlmg && (
          <MDBox
            {...getRootProps()}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            border="2px dashed"
            borderRadius="8px"
            borderColor="grey.400"
            bgcolor="grey.100"
            py={4}
            sx={{
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "grey.200",
              },
              transition: "background-color 0.2s ease-in-out",
            }}
          >
            <input {...getInputProps()} />
            <MDTypography variant="button" fontWeight="regular" color="textSecondary" mb={1}>
              Drag & drop an image here, or
            </MDTypography>
            <MDButton variant="contained" color="primary" size="medium">
              Browse Files
            </MDButton>
          </MDBox>
        )}

        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: "none" }}
          id="file-input"
        />

        {examData.mainlmg && (
          <MDBox
            mt={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            onClick={handleImageClick}
          >
            <img
              src={URL.createObjectURL(examData.mainlmg)}
              alt="Exam Main Image"
              style={{
                width: "100%",
                maxWidth: "150px",
                marginTop: "10px",
                borderRadius: "8px",
                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                cursor: "pointer",
              }}
            />
            <MDTypography variant="body2" color="textSecondary" mt={2}>
              Click to upload a new image
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
}

Media.propTypes = {
  examData: PropTypes.object.isRequired,
  updateExamData: PropTypes.func.isRequired,
};

function Settings({ examData, updateExamData }) {
  return <MDBox>{/* <MDTypography variant="h5">Settings</MDTypography> */}</MDBox>;
}

Settings.propTypes = {
  examData: PropTypes.object.isRequired,
  updateExamData: PropTypes.func.isRequired,
};

function ExamForm() {
  const [examData, setExamData] = useState({
    title: "",
    description: "",
    technologies: [],
    categories: [],
    copon: "",
    supports: [],
    prerequisites: [],
    objectifs: [],
    price: "",
    attemptsAllowed: "",
    reservedPlaces: "",
    onSite: "",
    remote: "",
    isActive: false,
    inSlider: false,
    sumOfSite: "",
    mainlmg: null,
    duration: "",
    language: "",
  });

  const [technologies, setTechnologies] = useState([]);
  const [categories, setCategories] = useState([]);
  const [supports, setSupports] = useState([]);
  const [copons, setcopons] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const technologyResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v2/technology/technologies`
        );
        setTechnologies(technologyResponse.data.data || []);

        const categoryResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v2/category/categories`
        );
        setCategories(categoryResponse.data.data || []);

        const supportResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/V2/slectronicsupport/all`
        );
        setSupports(supportResponse.data.electronicSupports || []);
        const coponResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v2/coupon/coupons`
        );
        setcopons(coponResponse.data.data || []);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);

  const updateExamData = (field, value) => {
    setExamData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const formData = new FormData();

      for (const [key, value] of Object.entries(examData)) {
        if (key !== "mainlmg") {
          formData.append(key, value);
        }
      }

      if (examData.mainlmg) {
        formData.append("mainlmg", examData.mainlmg);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/V2/exame/add-exame`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        Swal.fire({
          title: "Success!",
          text: "Exam added successfully!",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/dashboard/2-exams/exams-page");
        });
      }
    } catch (error) {
      console.error("Error adding exam:", error.response?.data || error.message);

      Swal.fire({
        title: "Error!",
        text: error.response?.data?.message || "Failed to add exam. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Add New Exam
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          This information will describe more about the Exam .
        </MDTypography>
      </MDBox>

      <MDBox pt={6} pb={3}>
        <MDBox mt={4}>
          <Card>
            <MDBox p={3}>
              <ExamDetails
                examData={examData}
                updateExamData={updateExamData}
                categories={categories}
                technologies={technologies}
                supports={supports}
                copons={copons}
              />
            </MDBox>
          </Card>
        </MDBox>
        <MDBox mt={4}>
          <Card>
            <MDBox p={3}>
              <Media examData={examData} updateExamData={updateExamData} />
            </MDBox>
          </Card>
        </MDBox>
        <MDBox mt={4}></MDBox>

        <MDBox mt={4} display="flex" justifyContent="flex-end">
          <MDButton variant="contained" color="primary" onClick={handleSubmit}>
            Submit Exam
          </MDButton>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ExamForm;
